import OverviewPage from '@mod-ww/pages/overview/overview';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-knowledgebase'))
    return;
});

dompack.register('.p-knowledgebaseindex__overview', node => new OverviewPage(node));
