:root {
  --mso-typewriter-color: var(--ww-primary-color);
  --mso-typewriter-cursor-color: var(--mso-typewriter-color);
}

.w-typewriter {
  color: var(--mso-typewriter-color);
}

.w-typewriter__container {
  display: block;
}
@media (min-width: 710px) {
  .w-typewriter__container {
    display: inline-block;
  }
}

.typed-cursor {
  color: var(--mso-typewriter-cursor-color);
}