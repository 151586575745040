.widget--textimageslider {
  display: flex;
  flex: 1;
}

.w-textimageslider {
  width: 100%;
}
.w-textimageslider__col {
  display: flex;
  flex: 1;
}
.w-textimageslider__col--image {
  width: 50%;
  float: left;
  overflow: hidden;
  display: flex;
  flex: 1;
}
.w-textimageslider__col--image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.w-textimageslider__col__content {
  width: 50%;
  float: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #E6E6E6;
}
.w-textimageslider__col__content__nav {
  display: flex;
  background: #A4A4A4;
}
.w-textimageslider__col__content__nav--panel {
  width: 13%;
  text-align: center;
  background-color: #A4A4A4;
}
.w-textimageslider__col__content__nav--left {
  float: left;
}
.w-textimageslider__col__content__nav--right {
  float: right;
}
.w-textimageslider__col__content__nav--title {
  background: #BDBDBD;
  text-align: center;
  padding: 1em 0 1em;
  width: 100%;
  font-weight: 700;
  flex: 0 0 auto;
}
.w-textimageslider__col__content__nav .fa {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.w-textimageslider__col__content__container, .w-textimageslider__col__content__nav__container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
  width: 100%;
}
.w-textimageslider__col__content__container .swiper-container, .w-textimageslider__col__content__nav__container .swiper-container {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .w-textimageslider__col__content__container .swiper-container .swiper-button-next, .w-textimageslider__col__content__container .swiper-container .swiper-button-prev, .w-textimageslider__col__content__nav__container .swiper-container .swiper-button-next, .w-textimageslider__col__content__nav__container .swiper-container .swiper-button-prev {
    top: auto;
    bottom: 5%;
  }
  .w-textimageslider__col__content__container .swiper-container .swiper-button-prev, .w-textimageslider__col__content__nav__container .swiper-container .swiper-button-prev {
    left: auto;
    right: 55px;
  }
}
.w-textimageslider__col__content--text {
  width: 100%;
  padding: 2em;
  flex: 0 0 auto;
}
@media screen and (max-width: 1024px) {
  .w-textimageslider__col__content__nav--panel {
    width: 20%;
  }
  .w-textimageslider__col__content__nav--title {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .w-textimageslider__col {
    flex-direction: column;
  }
  .w-textimageslider__col__content {
    width: 100%;
  }
  .w-textimageslider__col__content__nav--panel {
    width: 40%;
  }
  .w-textimageslider__col__content__nav--title {
    width: 100%;
  }
  .w-textimageslider__col--image {
    width: 100%;
    height: 300px;
  }
}