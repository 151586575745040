/* global $, Vue, flatpickr */
import RPCFormBase from '@mod-publisher/js/forms/rpc';
import FormBase from "@mod-publisher/js/forms/formbase";
import * as whintegration from '@mod-system/js/wh/integration';
import * as forms from '@mod-publisher/js/forms';
import * as dompack from 'dompack';
import JSONRPC from '@mod-system/js/net/jsonrpc';
import * as util from '../../shared/js/utilities';
import SignatureField from '../../shared/forms/signature/signature';
import { CustomSubjectsDefineField, CustomSubjectsInputsField } from '../../shared/forms/customsubjects/customsubjects';
import FileUploadField from '../../shared/forms/files/files';

const beforeUnloadListener = (event) => {
  event.preventDefault();
  new JSONRPC().async('UnmarkAsEditing');
  return event.returnValue = "Weet je zeker dat je deze pagina wilt verlaten?";
};

export default class ConversationForm extends RPCFormBase {
  constructor(node) {
    super(node);
  }
}

function getDefaultCustomSubject() {
  return { title: '', value: '', rating: '', isowner: true, canremove: true };
}

// notice: some things concerning custom group titles/tabs are commented; keep the code for a while since we don't know 100% sure yet if it should go

const VueApp = {
  data() {
    return {
      form: null,
      formSubmitted: false,
      // customSubjects: whintegration.config.obj.customsubjects.length ? whintegration.config.obj.customsubjects : [],
      customSubjectsPlaceholder: 'Vul hier je eigen onderwerp in',
      ratingdropdownitems: whintegration.config.obj.ratingdropdownitems.length ? whintegration.config.obj.ratingdropdownitems : [],
      conversationDateCET: whintegration.config.obj.conversationdate_cet,
      dateEditMode: false,
      conversationDatePicker: null,
      formtype: whintegration.config.obj.formtype,
      signature_dates: whintegration.config.obj.signature_dates,
    };
  },
  computed: {
    conversationDateCETFormatted() {
      if(this.conversationDateCET !== '') {
        return new Intl.DateTimeFormat('nl-NL').format(new Date(this.conversationDateCET));
      }
    },
  },
  created() {
    this.rpc = new JSONRPC();
  },
  async mounted() {
    // this.currentGroupTabIdx = 0;
    // this.numGroupTabs = 0;

    if (whintegration.config.obj.formtype === 'rating') {
      this.customSubjectsPlaceholder = 'Vul hier een competentie in';
    } else {
      // non-rating forms: subjects can always be removed (unless the user hasn't created it)
      // FIXME: fix in customsubjects.es?
      // this.customSubjects = this.customSubjects.map((subject, idx) => {
      //   subject.canremove = true;
      //   return subject;
      // });
    }

    // check & disable the checkboxes that should always be enabled
    $('.wh-form__fieldgroup--checkbox.alwaysenable').each(function() {
      let txt = 'altijd aangevinkt';
      if ($(this).hasClass('enabled-by-employee'))
        txt = 'aangevinkt door werknemer';
      else if ($(this).hasClass('enabled-by-employer'))
        txt = 'aangevinkt door leidinggevende';

      $(this).find('.wh-form__fields').append(`<div class="wh-form__fieldline--enabled-by">${txt}</div>`);
    });

    $('.wh-form__fieldgroup--checkbox.alwaysenable input').each(function() {
      if (!$(this).get(0).checked) {
        $(this).click(); // needs a real click to enable it
      }

      // can't set `disabled = true` since this disables the page and that's the opposite of what we want, so
      // just visually disable it (including pointer-events:none)
      $(this).closest('.wh-form__fieldgroup').addClass('showasdisabled');
    });

    // Set textarea to readonly to allow scroll and disallow user input
    $('.wh-form__fieldgroup--textarea.readonly').each(function() {
      $(this).find('textarea').attr("readonly", true);
    });

    // checks for tooltips and adds buttons to display them
    $('.wh-form__fieldline--hasinfotext').each(function() {
      const tooltipTitle = $(this).find('.wh-form__infotextwrapper').html();
      $(this).append(
        `<a class="fa fa-info-circle wh-form__my-tooltipcontainer"
            data-bs-toggle="modal"
            data-bs-target="#infoModal"
            data-whatever='${tooltipTitle}'>
         </a>`);
    });


    if(dompack.qS("#webtoolform-signature_employee_date")) {
      dompack.qS("#webtoolform-signature_employee_date").addEventListener('change', function(e) {
        if(!e.target.value) {
          FormBase.setFieldError(e.target, 'Dit veld is verplicht', { reportimmediately: true });
        } else {
          FormBase.setFieldError(e.target, '', { reportimmediately: true });
        }
      });
    }

    if(dompack.qS("#webtoolform-signature_employer_date")) {
      dompack.qS("#webtoolform-signature_employer_date").addEventListener('change', function(e) {
        if(!e.target.value) {
          FormBase.setFieldError(e.target, 'Dit veld is verplicht', { reportimmediately: true });
        } else {
          FormBase.setFieldError(e.target, '', { reportimmediately: true });
        }
      });
    }


    $('.wh-form__fieldgroup--upload').each(function() {
      const thisRef = this;
      const inputEl = $(this).find('.wh-form__upload');
      if(!$(this).find('.wh-form__label').length) {
        $(this).append(`<label class="wh-form__label" for="${inputEl.attr('id')}">Bestand kiezen</label>`);
      }

      $(this).append(`<div class="filename"><p class="filename--text">Geen bestand gekozen</p></div>`);

      inputEl.on('change', function(){
        const filename = $(this).val();
        $(thisRef).find('.filename--text').html(filename.substr(12, filename.length));
      });

      inputEl.addClass("visually-hidden");
      $(this).find('.wh-form__fields').addClass('visually-hidden');
    });

    // add a modal container
    $('body').append(`<!-- Modal -->
      <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body"></div>
          </div>
        </div>
      </div>`);

    $('#infoModal').on('shown.bs.modal', function (event) {
      let button = $(event.relatedTarget); // Button that triggered the modal
      const content = button.data('whatever'); // Extract info from data-* attributes
      let modal = $(this);
      modal.find('.modal-body').html(content);
    });

    // remove normal submit button and add a custom 'save' button
    const submitButtonTitle = $('.wh-form__button--submit').text();
    $('.wh-form__button--submit').remove();

    if (whintegration.config.obj.cansubmitform) {
      $('.wh-form .wh-form__navbuttons').append(
        `<button type="submit" class="wh-form__button wh-form__button--submit wh-form__button--save js-save-form" ${whintegration.config.obj.formsubmitbuttondisabled ? 'disabled' : ''} style="display:inline-flex">
           <span class="wh-form__buttonlabel">${submitButtonTitle}</span>
         </button>`);
    }

    // FIXME: fix in customsubjects.es
    // if(whintegration.config.obj.formtype === 'rating')
    //   this.updateSubmitButtonsOnSubjectInput();

    // make save button jump to the last page and submit the form
    // FIXME: no support yet, https://forum.webhare.dev/t/submit-a-webhare-form-prematurely/99
    $('.wh-form').find('.js-save-form').click(async(evt) => {
      evt.preventDefault();

      $(evt.target).closest('.js-save-form').addClass('disabled'); // show as disabled

      const signatures = { hadInitialSignatureEmployer: await this.hadInitialSignature('employer'),
                           hadInitialSignatureEmployee: await this.hadInitialSignature('employee'),
                           hasSignatureEmployer: await this.hasSignature('employer'),
                           hasSignatureEmployee: await this.hasSignature('employee'),
                         };

      if (!signatures.hadInitialSignatureEmployee && signatures.hasSignatureEmployee) {
        dompack.stop(evt);

        if (!dompack.qS("#webtoolform-signature_employee_date").value) {
          FormBase.setFieldError(dompack.qS("#webtoolform-signature_employee_date"), 'Dit veld is verplicht', { reportimmediately: true });
          return;
        } else {
          FormBase.setFieldError(dompack.qS("#webtoolform-signature_employee_date"), '', { reportimmediately: true });
        }

        const confirmTxt = whintegration.config.obj.formtype === 'rating'
          ? 'Door het invullen van de handtekening wordt het formulier afgesloten.<br><br>Weet je zeker dat je het formulier wilt opslaan en verzenden?'
          : 'Je hebt je handtekening gezet onder het formulier. Als je nu kiest voor ‘Opslaan en verzenden’ wordt het formulier afgesloten en kun je het niet meer bewerken. Je leidinggevende ontvangt een bericht met de vraag om het formulier te controleren en te ondertekenen.<br><br>Weet je zeker dat je het formulier wilt opslaan en verzenden?';

        util.confirm(confirmTxt,
          async result => {
            if (result === true) {
              try {
                await this.form.submit();
                util.alert('Het formulier is opgeslagen.',
                           async result => {
                             await this.redirect(whintegration.config.obj.overviewlink);
                           }, 'Formulier opgeslagen');
              } catch (e) {
                util.triggerGeneralErrorDialog(e);
              }
            }
          }, 'Formulier afsluiten');
      }
      else if (!signatures.hadSignatureEmployer && signatures.hasSignatureEmployer) {
        dompack.stop(evt);

        if (!dompack.qS("#webtoolform-signature_employer_date").value) {
          FormBase.setFieldError(dompack.qS("#webtoolform-signature_employer_date"), 'Dit veld is verplicht', { reportimmediately: true });
          return;
        } else {
          FormBase.setFieldError(dompack.qS("#webtoolform-signature_employer_date"), '', { reportimmediately: true });
        }

        const confirmTitle = whintegration.config.obj.formtype === 'rating'
          ? 'Formulier verzenden'
          : 'Formulier afsluiten';

        const confirmTxt = whintegration.config.obj.formtype === 'rating'
          ? 'Door het verzenden van het formulier met een ingevulde handtekening wordt een e-mail verzonden naar de werknemer met een link naar het formulier. Het formulier is daarmee in te zien voor de werknemer en hij/zij kan middels zijn/haar handtekening het formulier definitief afsluiten.<br><br>Weet je zeker dat het formulier verzonden moet worden?'
          : 'Je hebt je handtekening gezet onder het formulier. Als je nu kiest voor ‘Opslaan en verzenden’ wordt het formulier definitief afgesloten. Er wordt een pdf gemaakt en het formulier is niet meer aan te passen.<br><br>Weet je zeker dat je het formulier wilt opslaan en verzenden?';

        util.confirm(confirmTxt,
          async result => {
            if (result === true) {
              try {
                await this.form.submit();

                const alertTitle = whintegration.config.obj.formtype === 'rating'
                  ? 'Formulier verzonden'
                  : 'Formulier afgesloten';

                const alertTxt = whintegration.config.obj.formtype === 'rating'
                  ? 'Het formulier is verzonden.'
                  : 'Het formulier is afgesloten.';

                util.alert(alertTxt,
                           async result => {
                             await this.redirect(whintegration.config.obj.overviewlink);
                           }, alertTitle);
              } catch (e) {
                util.triggerGeneralErrorDialog(e);
              }
            }
          }, confirmTitle);
      } else {
        try {
          await this.form.submit();
          util.alert('Het formulier is succesvol opgeslagen en te vinden onder "Openstaande gesprekken" in het overzicht.<br><br>Je kunt het formulier afsluiten of verder gaan met invullen.',
                     async result => { // fixme: redirect when not in editmode
                      if (!whintegration.config.obj.editmode)
                        await this.redirect(whintegration.config.obj.overviewlink);
                     }, 'Formulier opgeslagen');
        } catch (e) {
          util.triggerGeneralErrorDialog(e);
        }
      }

      $(evt.target).closest('.js-save-form').removeClass('disabled');
    });

    const uploadgroups = [[]];
    let lastIndex = -1;
    let groupIndex = 0;
    $('.wh-form__fieldgroup').each(function(idx, el) {
      if(el.classList.contains('wh-form__fieldgroup--upload')) {
        // If the current upload field is not adjacent to the previous one
        // and its not the first element, create new group
        if(lastIndex !== idx-1 && lastIndex !== -1) {
          groupIndex++;
          uploadgroups.push([]);
        }

        lastIndex = idx;
        uploadgroups[groupIndex].push(el);
      }
    });

    uploadgroups.forEach(arr => {
      if(arr.length > 1)
        $(arr).wrapAll('<div class="wh-form--uploadgroup d-flex"></div>');
    });

    dompack.register(".wh-form__page", page => page.addEventListener("wh:form-pagechange", evt => this.onPageChange(false)));
    this.onPageChange(true);

    // we assume the 2nd page contains the checkboxes to enable/disable conversation subjects and that all checkboxes
    // on this 2nd page are meant for conversation subjects
    const pages = dompack.qSA('.wh-form__page');
    if (pages.length > 1) {
      this.setupSubjectsSelection();
    }

    // if we're editing, logged in user is employer, this is not a rating form AND form state is open,
    // we need to let the user know that we need an employee signature first
    if (whintegration.config.obj.editmode
        && whintegration.config.obj.isemployer
        && whintegration.config.obj.formtype !== 'rating'
        && whintegration.config.obj.formstate === 'open') {
      const $signatureNote = $('<p class="wh-form__fullwidth">De werknemer heeft zijn/haar handtekening nog niet gezet.</p>');
      $('.wh-form__fieldgroup--signature[data-wh-form-group-for="signature_employer"]').after($signatureNote);
    }
    // listen to the 'form is done' event
    // dompack.qS('.wh-form').addEventListener('wh:form-response', function(event) {
    //   if (event.detail && event.detail.result && event.detail.result.resultsguid) {
    //     try {
    //       this.redirect(whintegration.config.site.pages.myforms;
    //       $('.js-save-form').remove();
    //     } catch(e) {
    //       console.error(e);
    //     } finally {
    //       $('.js-save-form').removeClass('disabled'); // if something goes wrong, re-enable the button
    //     }
    //   }
    // });

    // we assume the second page contains the groups
    // const $pageWithGroups = $('.wh-form__page').eq(1);
    // $pageWithGroups.addClass('wh-form__page--hasgroupcontainers');

    // const $pageContainer = $(`<div class="wh-form__carmelgrouptabscontainer"></div>`);
    // $pageWithGroups.prepend($pageContainer);

    // restructure the form groups
    // let lastGroupId = -1; // counter
    // $pageWithGroups.find('.wh-form__fieldgroup').each(function() {
    //   if ($(this).hasClass('wh-form__fieldgroup--group')) { // it's a group, which enables checkboxes based on their groups
    //     lastGroupId++;
    //     $(this).addClass('js-select-group wh-form__myfieldgrouptab').get(0).setAttribute('data-groupid', lastGroupId);
    //     // add to our pagecontainer
    //     $pageContainer.append($(this));
    //     // if it's the first, select it (the container behaves like a tab)
    //     if (lastGroupId == 0) {
    //       $(this).addClass('selected');
    //     }
    //   }
    //   else {
    //     // add some classes and set the group id
    //     $(this).addClass('wh-form__carmeltabitem js-group-item').get(0).setAttribute('data-groupid', lastGroupId);
    //     // add to our pagecontainer
    //     $pageContainer.append($(this));
    //     // if it's in the first group, we can show it (for tab navigation)
    //     if (lastGroupId == 0) {
    //       $(this).show();
    //     }
    //   }
    // });

    // checks if there are more than one grouptabs and hides the grouptab when only one is present
    // if($pageWithGroups.find('.wh-form__myfieldgrouptab').length < 2) {
    //   $($pageWithGroups.find('.wh-form__myfieldgrouptab')).css("display", "none");
    // }

    // add click events to the groups (they behave like tabs)
    // $('.js-select-group').click(evt => {
    //   evt.preventDefault();

    //   // get the id from data attribute and set the correct group tab
    //   const $select = $(evt.target).closest('.js-select-group');
    //   this.setGroupTab(parseInt($select.data('groupid')));
    // });

    // this.numGroupTabs = $('.js-select-group').length;

    // // init group tabs
    // this.setGroupTab(0);

    // $('button[data-wh-form-action="previous"]').click(evt => {
    //   const onGroupContainersPage = this.onGroupContainersPage();
    //   if (onGroupContainersPage && this.currentGroupTabIdx > 0) {
    //     dompack.stop(evt);
    //     this.setGroupTab(this.currentGroupTabIdx - 1, true);
    //   }
    // });

    // $('button[data-wh-form-action="next"]').click(evt => {
    //   const onGroupContainersPage = this.onGroupContainersPage();
    //   if (onGroupContainersPage && this.currentGroupTabIdx < this.numGroupTabs - 1) {
    //     dompack.stop(evt);
    //     this.setGroupTab(this.currentGroupTabIdx + 1, true);
    //   }
    // });

    // if we're in an iframe, we assume it's the publisher preview, so we want to remove the submit/save buttons
    // FIXME: this interferes with unit tests
    // if (util.inIframe()) {
    //   $('.wh-form__button--submit').remove();
    // }

    this.enablePageUnloadListener();

    forms.registerHandler("gesprekkenapp:formhandler", form => {
      this.form = new ConversationForm(form);
      this.form.getFormExtraSubmitData = this.getFormExtraSubmitData;
      this.form.onSubmitSuccess = this.onSubmitSuccess;
      window.form = this.form;
      return this.form;
    });

    dompack.register(".signature", node => new SignatureField(node, { signature_dates: this.signature_dates }));
    dompack.register(".customsubjectsdefine", node => new CustomSubjectsDefineField(node));
    dompack.register(".customsubjectsinputs", node => new CustomSubjectsInputsField(node));
    dompack.register('.fileupload', node => new FileUploadField(node));

    // Use flatpickr for every wh date field
    $('.wh-form__date').each(function() {
      flatpickr(this, {
          locale: "nl",
          altInput: true,
          allowInput: true,
          altFormat: "d-m-Y",
          dateFormat: "Y-m-d",
          weekNumbers: true,
          defaultDate: this.value != undefined ? flatpickr.parseDate(this.customDateStr, "Y-m-d  ") : Date.now(),
        });
    });

    document.documentElement.classList.remove('wait-for-init');

    if (dompack.debugflags.wouterdebug) {
      this.form.gotoPage(1);
      $('[data-bs-target]').click();

      if (!whintegration.config.obj.editmode) {
        // $('.customsubjectsdefine__add').first().trigger('click');
        // $('.customsubjectsdefine__rowinput').first().val('Testonderwerp');

        // for (const selectEl of dompack.qSA('.wh-form__fieldgroup--custom.customsubjectsdefine select')) {
        //   selectEl.value = 'Comp 1';
        // }
        if (whintegration.config.obj.formtype === 'rating') {
          // this.customSubjects = [{ ...getDefaultCustomSubject(), title: 'Comp 1' },
          //                        { ...getDefaultCustomSubject(), title: 'Comp 2' },
          //                        { ...getDefaultCustomSubject(), title: 'Comp 3' },
          //                        { ...getDefaultCustomSubject(), title: 'Comp 1' },
          //                       ];
          // this.updateSubmitButtonsOnSubjectInput();
        }
        else if (whintegration.config.obj.formtype === 'development') {

        }
      }
      // this.dateEditMode=true;
      // this.editConversationDate();
    }
  },
  methods: {
    setupSubjectsSelection() {
      const page2 = dompack.qSA('.wh-form__page')[1];
      const conversationSubjectCheckboxes = page2.querySelectorAll('.wh-form__checkbox');
      window.addConversationSubjects = [];
      for (const checkbox of conversationSubjectCheckboxes) {
        checkbox.addEventListener('change', (evt) => {
          if (evt.target.checked) {
            // if not in array yet, add it
            if (!window.addConversationSubjects.includes(evt.target.name)) {
              window.addConversationSubjects.push(evt.target.name);
            }
          }
          else {
            // it's unchecked, remove from array if it's in there
            window.addConversationSubjects = window.addConversationSubjects.filter(subject => subject !== evt.target.name);
          }
        });
      }
    },
      // FIXME: fix in customsubjects.es
    // onSubjectInput(evt) {
    //   if (whintegration.config.obj.formtype !== 'rating')
    //     return;

    //   this.updateSubmitButtonsOnSubjectInput();
    // },
    async getFormExtraSubmitData() {
      const formValue = await this.form.getFormValue();

      return { signature_employer: formValue.signature_employer,
               signature_employee: formValue.signature_employee,
               // customSubjects: this.customSubjects,
               conversationDate: this.conversationDateCET,
               addConversationSubjects: window.addConversationSubjects,
             };
    },
    async onSubmitSuccess() {
      util.devLog('onsubmitsuccess');
      this.formSubmitted = true;
      await this.rpc.async('UnmarkAsEditing');
    },
    // addCustomSubject() {
    //   this.customSubjects.push(getDefaultCustomSubject());
    //   window.customSubjects = this.customSubjects;
    // },
    // removeCustomSubject(idx) {
    //   const subject = this.customSubjects[idx];
    //   if (subject.value !== '') {
    //     util.alert('Item kan niet verwijderd worden omdat er al een waarde voor is ingevuld.', null, 'Kan niet verwijderen');
    //     return;
    //   }
    //   // FIXME: confirmation popup (util.confirm)
    //   this.customSubjects.splice(idx, 1);
    // },
    revertFormToOpen() {
      util.confirm('Hiermee wordt het formulier opengezet voor de medewerker en kan hij/zij het formulier weer aanpassen. De handtekening van de werknemer wordt hierbij verwijderd.<br><br>Weet je zeker dat je het formulier open wilt zetten voor de medewerker?', async result => {
        if (result === true) {
          try {
            await this.rpc.async('RevertFormToOpen');
            this.redirect(whintegration.config.obj.overviewlink);
          } catch (e) {
            util.triggerGeneralErrorDialog(e);
          }
        }
      }, 'Formulier openzetten');
    },
    redirect(href) {
      this.disablePageUnloadListener();
      location.href = href;
    },
    formIsDisabled() {
      const result = this.formSubmitted || whintegration.config.obj.formstate === 'pending';
      util.devLog('formIsDisabled', this.formSubmitted, whintegration.config.obj.formstate === 'pending');
      return result;
    },
    enablePageUnloadListener() {
      if (!this.formIsDisabled() && dompack.debugflags['disableunloadlistener'] !== true)
        addEventListener("beforeunload", beforeUnloadListener, {capture: true});
    },
    disablePageUnloadListener() {
      removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
    },
    async hadInitialSignature(type = '') {
      const $signature = $(`.signature[data-wh-form-name="signature_${type}"]`);
      if (!$signature.length)
        return false;

      return typeof($signature.get(0).dataset.signatureValue) !== 'undefined';
    },
    // returns whether a signature has been set for `type`: either before the form (showing the signature image) or 'fresh'
    // type: employer, employee
    async hasSignature(type = '') {
      const $signature = $(`.signature[data-wh-form-name="signature_${type}"]`);
      if (!$signature.length)
        return false;

      const signatureSetEarlier = !!$signature.get(0).dataset.signatureValue;
      if (signatureSetEarlier) {
        return true;
      }

      if (!this.form)
        return false;

      const formValue = await this.form.getFormValue();
      const formSignature = formValue[`signature_${type}`];
      return !!formSignature;
    },
    editConversationDate() {
      this.dateEditMode = true;

      Vue.nextTick(() => {
        this.conversationDatePicker = flatpickr(this.$refs.conversationDatePicker, {
          locale: "nl",
          altInput: true,
          allowInput: true,
          altFormat: "d-m-Y",
          dateFormat: "Y-m-d",
          weekNumbers: true,
          defaultDate: this.conversationDateCET,
          onClose: (selDates, dateStr, instance) => {
            if(selDates.length > 0) {
              this.conversationDateCET = selDates[0];
            }
            this.dateEditMode = false;
          },
        });
        this.conversationDatePicker.open();
      });
    },
    // onGroupContainersPage() {
    //   return $('.wh-form__page--visible').hasClass('wh-form__page--hasgroupcontainers');
    // },
    // async setGroupTab(idx = 0, scrollToTop = false) {
    //   // deselect all tabs, select the correct one
    //   $('.js-select-group').removeClass('selected').eq(idx).addClass('selected');

    //   // hide all checkboxes, show the correct ones
    //   $('.js-group-item').hide();
    //   $(`.js-group-item[data-groupid="${idx}"]`).show();

    //   this.currentGroupTabIdx = idx;

    //   if (scrollToTop === true) {
    //     dompack.qS('.wh-form').scrollIntoView({ behavior: 'smooth' });
    //   }

    //   this.updateFormButtons();
    // },
    onPageChange(isInit = false) {
      this.updateFormButtons();

      if (!this.formIsDisabled())
        this.rpc.async('MarkAsEditing');
    },
    async updateFormButtons() {
      let currentPage = dompack.qS('.wh-form__page--visible');
      let pageIndex = dompack.qSA('.wh-form__page').indexOf(currentPage);

      let pageType = '';
      if (pageIndex == 0) {
        pageType = 'intro';
      }

      // let nextButtonTitle = 'Volgende';
      // if (currentPage && currentPage.classList.contains('wh-form__page--hasgroupcontainers')) {
      //   pageType = 'themes';

      //   // update the 'next' button title based on whether we're on the last tab
      //   // disabled for now, doesn't really make sense tbh
      //   // let selectedTabIdx = 0;
      //   // $('.js-select-group').each(function(idx) {
      //   //   if ($(this).hasClass('selected')) {
      //   //     selectedTabIdx = parseInt($(this).data('groupid'));
      //   //     return false;
      //   //   }
      //   // });
      //   // const isLastTab = selectedTabIdx == $('.js-select-group').length - 1;
      //   // if (isLastTab) {
      //   //   nextButtonTitle = 'Gesprek starten';
      //   // }
      // }

      const nrPages = dompack.qSA('.wh-form__page:not([data-wh-form-pagerole="thankyou"])').length;
      const isLastPage = pageIndex == nrPages - 1;

      util.devLog('page:', pageType, pageIndex, 'nrpages:', nrPages, 'isLastPage:', isLastPage);

      if (dompack.qS('.wh-form__button--submit'))
        dompack.qS('.wh-form__button--submit').hidden = pageType == 'intro' && whintegration.config.obj.formtype !== 'rating';

      if (whintegration.config.obj.showsubmitonlastpage) {
        $('.wh-form__button--submit').attr('hidden', !isLastPage);
      }

      // fix for submit buttons text when signature is present, but user goes back to previous pages
      const hasSignatureEmployee = await this.hasSignature('employee');
      // const hasSignatureEmployer = await this.hasSignature('employer');

      if(hasSignatureEmployee && whintegration.config.obj.formtype === 'rating') {
        $('.wh-form__button--submit').text('Verzenden');
      } else if(!hasSignatureEmployee && whintegration.config.obj.formstate !== 'open' && whintegration.config.obj.formtype === 'rating') {
        $('.wh-form__button--submit').attr('disabled', true);
      } else if (whintegration.config.obj.isemployee && hasSignatureEmployee && whintegration.config.obj.formstate === 'pending') {
        $('.wh-form__button--submit').attr('disabled', true);
        $('.wh-form__button--submit').attr('hidden', true);
      } else if(hasSignatureEmployee) {
        $('.wh-form__button--submit').text(isLastPage ? 'Opslaan & verzenden' : 'Tussentijds opslaan');
      }
    },
  }
};

dompack.onDomReady(() => {
  Vue.createApp(VueApp).mount('#vue');
});
