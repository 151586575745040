import 'filepond/dist/filepond.min.css';
import './files.scss';

import * as FilePond from 'filepond';
import * as dompack from 'dompack';
import FormBase from '@mod-publisher/js/forms/formbase';

export default class FileUploadField {
  constructor(node) {
    this.node = node;
    this.pondNode = node.querySelector('.fileupload__pond');

    this.isRequired = this.node.dataset.required == 'true';
    this.isDisabled = this.node.dataset.disabled;

    this.initialValue = this.node.dataset.value;
    this.initialFilename = this.node.dataset.filename;
    this.initialMimetype = this.node.dataset.mimetype;
    this.initialSize = this.node.dataset.filesize;
    this.initialFile = this.initialValue ? new File([this.initialValue], this.initialFilename, {type: this.initialMimetype}) : null;
    this.initialFileId = "0";
    this.initialValueChanged = false;

    this._fieldgroup = dompack.closest(this.node, '.wh-form__fieldgroup');

    const options = {
      multiple: false,
      credits: false,
      required: !this.isDisabled && this.isRequired,
      disabled: this.isDisabled,
      name: 'ignoreme_' + this.node.dataset.whFormName,
      labelIdle: 'Sleep of <span class="filepond--label-action">kies</span> bestand',
      labelInvalidField: 'Ongeldig bestand',
      labelFileLoading: 'Laden...',
      labelFileLoadError: 'Error tijdens laden',
      labelTapToRetry: 'Opnieuw proberen'
    };

    if(this.initialValue) {
      options.files = [
        {
          source: this.initialFile,

          options: {
            type: 'local',
            file: {
              name: this.initialFilename,
              type: this.initialMimetype,
              size: this.initialSize
            }
          }
        }
      ];
    }

    this.pond = FilePond.create(options);

    this.pond.on('fileremove', (error, file) => {
      if(file.id === this.initialFileId) {
        this.initialValueChanged = true;
      }
    });


    this.init();
  }

  get hasValue() {
    return this.pond.getFile() !== null;
  }

  init() {
    this.pondNode.appendChild(this.pond.element);

    const initialFile = this.pond.getFile();
    if(initialFile) {
      this.initialFileId = initialFile.id;
    }

    if (this._fieldgroup) {
      this._fieldgroup.dataset.whFormEnableListener = true;
    }

    this.node.whFormsApiChecker = () => this._check();
    this.node.whUseFormGetValue = true;
    this.node.addEventListener("wh:form-getvalue", event => this.getValue(event));
  }

  _check() {
    if (this.isRequired && !this.hasValue)
      FormBase.setFieldError(this.node, 'Dit veld is verplicht', { reportimmediately: false });
    else
      FormBase.setFieldError(this.node, "", { reportimmediately: false });
  }

  async getData(file) {
    const link = await this.getDataURL(file);

    return {
      link,
      filename: file.filename,
    };
  }

  getDataURL(file) {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        res(reader.result);
      });

      reader.addEventListener('error', () => {
        rej('Error occurred while trying to read file: ' + file.filename);
      });

      reader.readAsDataURL(file.file);
    });
  }

  getValue(event) {
    event.preventDefault();
    event.stopPropagation();


    if(this.initialValue && !this.initialValueChanged) {
      event.detail.deferred.resolve({link: this.initialValue, filename: this.initialFilename});
      return;
    }

    const file = this.pond.getFile();

    if(file) {
      this.getData(file).then((data) => {
        event.detail.deferred.resolve(data);
      }).catch((error) => {
        event.detail.deferred.reject(error);
      });
    } else {
      event.detail.deferred.resolve(null);
    }
  }
}
