/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  --hover-image-scale: 1.1;
}

@media (max-width: 767.98px) {
  .widget--ctaslider {
    padding-right: 0 !important;
  }
}

.w-ctaslider {
  --ww-widget-ctas-image-aspect-ratio: 100%;
  --shift-count: 0;
  margin: 0 auto;
}
.w-ctaslider__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .w-ctaslider__header {
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .w-ctaslider__header {
    padding-right: var(--ww-grid-padding-x);
  }
}
.w-ctaslider__cols {
  display: flex;
  gap: 2em;
  overflow: visible !important;
}
@media (max-width: 767.98px) {
  .w-ctaslider__cols {
    overflow-x: scroll;
  }
}
.w-ctaslider__col {
  margin-bottom: 15px;
  width: 80%;
  flex-shrink: 0;
  transform: translateX(calc((-100% - 2em) * var(--shift-count)));
  transition: transform 300ms;
}
@media (min-width: 576px) {
  .w-ctaslider__col {
    width: 392px;
  }
}
.w-ctaslider__col-inner {
  position: relative;
}
.w-ctaslider__actions {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}
.w-ctaslider__actions .w-ctaslider__action {
  width: 40px;
  height: 40px;
  font-size: 1.25em;
  background-color: var(--ww-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .w-ctaslider__actions {
    padding-right: var(--ww-grid-padding-x);
  }
}

.w-ctaslider__imagelinkcontainer {
  max-width: 100% !important;
  margin-bottom: 0 !important;
  height: 100%;
}
.w-ctaslider__imagelink {
  display: block;
  height: 0;
  position: relative;
  padding-bottom: var(--ww-widget-ctas-image-aspect-ratio);
}
.w-ctaslider__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.w-ctaslider__text {
  padding: var(--ww-widget-ctas-text-padding);
  max-width: 100%;
}
.w-ctaslider__text > * {
  margin-top: 0;
  margin-bottom: 1em;
}
.w-ctaslider__text h3 {
  font-size: 1.5rem;
}
.w-ctaslider__text h4 {
  font-size: 1.125rem;
}
.w-ctaslider__text .btn {
  margin-top: 1em;
}
.w-ctaslider__text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
  color: var(--ww-widget-ctas-text-color);
}
.w-ctaslider__text > *:first-child {
  margin-top: 0;
}
.w-ctaslider__text > *:last-child {
  margin-bottom: 0;
}
.w-ctaslider__text > * {
  color: inherit;
}
.w-ctaslider__text .btn {
  display: none;
}
.w-ctaslider--textbelow .w-ctaslider__text {
  position: static;
}
.w-ctaslider__image {
  transition: transform 0.3s;
}
.w-ctaslider__imagelink {
  overflow: hidden;
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
.w-ctaslider__imagelinkoverlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ww-widget-ctas-image-overlay);
  transition: opacity 0.3s;
}
@media (hover: hover) {
  .w-ctaslider__imagelinkoverlay:hover {
    opacity: var(--ww-widget-ctas-image-overlay-opacity-hover);
  }
}

.w-ctaslider__imagelinkoverlay:hover ~ .w-ctaslider__image {
  transform: scale(1.1);
}

.w-ctaslider__popup {
  min-height: 20%;
  max-height: 80%;
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  overflow: hidden;
}
.w-ctaslider__popup--listener {
  cursor: pointer;
}
.w-ctaslider__popup--backdrop {
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.w-ctaslider__popup--content {
  width: 100%;
  height: 100%;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
  object-fit: cover;
  padding: 1.5rem;
  overflow-y: auto;
}
.w-ctaslider__popup--btncontainer {
  box-shadow: rgba(0, 0, 0, 0.07) 5px 5px 35px 0px;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.w-ctaslider__popup--listener:hover ~ .w-ctaslider__col-inner #w-ctasliderimg {
  transform: scale(var(--hover-image-scale));
}

#popup-backbutton {
  width: 24px !important;
  height: 24px !important;
  fill: var(--ww-primary-color);
}

.w-ctaslider__hover--container {
  display: inline-block;
  text-align: left;
}
.w-ctaslider__hover--container:hover .w-ctaslider__hover--content {
  max-height: 300px;
}

.w-ctaslider__hover--content {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  max-height: 0;
  border-radius: 8px;
}

.w-ctaslider__hover--inner {
  padding: 10px 0 10px 15px;
}

.w-ctaslider__hover--toggle {
  border-radius: 3px;
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
}