.footer {
  background: #1A95AD;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #fff;
  margin-top: 4rem;
  text-align: center;
}
.footer a {
  color: #fff;
}
.footer .devider {
  padding: 0 15px;
}