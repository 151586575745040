/* global $ */
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from "dompack";
import FormBase from "@mod-publisher/js/forms/formbase";
import * as util from '../../js/utilities';

import SignaturePad from 'signature_pad';

export default class Signature
{
  constructor(node, options) // node = <div class="signature">
  {
    // setup properties
    this.hasValue = false;
    this.options = options;

    // to be able to initialize, we first need to show all pages
    $('.wh-form__page').css('display','block');

    if (dompack.qS('#js-form-name-employer') && node.dataset.whFormName === 'signature_employer') {
      dompack.qS(node, '.js-signature-name').innerText = this.getFieldTitle('employer');
    }

    if (dompack.qS('#js-form-name-employee') && node.dataset.whFormName === 'signature_employee') {
      dompack.qS(node, '.js-signature-name').innerText = this.getFieldTitle('employee');
    }

    // Initialize DOM elements
    this.node = node;
    this.canvasNode = dompack.qS(node, ".js-signature-canvas");
    this.imgNode = dompack.qS(node, ".js-signature-image");
    this.clearNode = dompack.qS(node, ".js-signature-clear");
    this.undoNode = dompack.qS(node, ".js-signature-undo");
    this._fieldgroup = dompack.closest(this.node,".wh-form__fieldgroup");
    this._fieldgroup.dataset['disabled'] = node.dataset.signatureDisabled;

    // Initialize canvas
    let rect = this.canvasNode.getBoundingClientRect();
    this.canvasNode.width = rect.width;
    this.canvasNode.height = rect.height;
    this.canvasContext = this.canvasNode.getContext("2d");

    // Field initialization
    const hasUploadValue = this.node.dataset.signatureValue !== '';
    this.isRequired = !hasUploadValue && this.node.dataset.signatureRequired == "true";
    this._updateEnabledStatus(this.node.dataset.signatureDisabled != "true");
    this.node.whFormsApiChecker = () => this._check();
    this.node.whUseFormGetValue = true;
    this.node.addEventListener("wh:form-getvalue", event => this.getValue(event));

    // Initialize fieldgroup
    if (this._fieldgroup) {
      this.node.dataset.whFormEnableListener = true;
    }

    // Init canvas drawing mechanism
    const canvasOptions = {
      onBegin: this.onStartDraw.bind(this),
    };

    this.signaturePad = new SignaturePad(this.canvasNode, canvasOptions);

    // Clear the field on clicking the clear button
    this.clearNode.addEventListener("click", event => this._clear(event));

    // If there already is a value, show it
    if (this.node.dataset.signatureValue) {
      this.setValue(this.node.dataset.signatureValue);
      this.node.classList.add('signature--disabled');
      this.canvasNode.style.display = 'none';
      this.imgNode.src = this.node.dataset.signatureValue;
      $(this.undoNode).hide();

      this._fieldgroup.dataset['isset'] = true;
    }
    else {
      this._clear();
    }

    $(this.undoNode).click(() => {
      let data = this.signaturePad.toData();
      if (data) {
        data.pop(); // remove the last drawn dot or line
        this.signaturePad.fromData(data); // trigger canvas reload

        if (data.length == 0) {
          this.reset();
        }
      }
    });

    // reset the form pages to their original visibility
    $('.wh-form__page').removeAttr('style');
  }

  getFieldTitle(usertype) {
    const fieldname = dompack.qS(`#js-form-name-${usertype}`).dataset.name;
    let result = fieldname;

    return result;
  }

  onStartDraw() {
    this.handleCanvasDrawn();
  }

  toggleButtons(show = false) {
    $(this.clearNode).toggleClass('show', show);
    $(this.undoNode).toggleClass('show', show);

    if (this.node.dataset.whFormName === 'signature_employer') {
      if (whintegration.config.obj.formstate === 'pending' && whintegration.config.obj.isemployer) {
        $('.wh-form__button--submit').get(0).disabled = !show;
      }

      if (whintegration.config.obj.formtype === 'rating') {
        $('.wh-form__button--submit').text(show || whintegration.config.obj.isemployee ? 'Opslaan & verzenden' : 'Tussentijds opslaan');
      }
    } else if (this.node.dataset.whFormName === 'signature_employee') {
      if (whintegration.config.obj.formtype === 'rating') {
        $('.wh-form__button--submit').text(show || whintegration.config.obj.isemployee ? 'Verzenden' : 'Tussentijds opslaan');
        $('.wh-form__button--submit').attr('disabled', !show);
      } else {
        $('.wh-form__button--submit').text(show ? 'Opslaan & verzenden' : 'Tussentijds opslaan');
      }
    }
  }

  _clear(event)
  {
    if (event) {
      dompack.stop(event);

      // trigger confirm if from clear event
      util.confirm('Weet je zeker dat je de handtekening wilt verwijderen?', result => {
        if (result !== true) {
          return;
        }

        this.reset();
      }, 'Handtekening verwijderen');
    }
  }

  reset() {
    // Clear state
    this.isDirty = false;
    this.hasValue = false;

    // Clear drawing
    this.signaturePad.clear();

    // Hide clear & undo buttons
    this.toggleButtons(false);
  }

  _check()
  {
    if (this.isRequired && !this.hasValue && !this.isDirty)
      FormBase.setFieldError(this.node, 'Dit veld is verplicht', { reportimmediately: false });
    else
      FormBase.setFieldError(this.node, "", { reportimmediately: false });
  }

  handleCanvasDrawn()
  {
    // Set dirty
    this.isDirty = true;

    // Show the clear * undo buttons
    this.toggleButtons(true);
  }

  _updateEnabledStatus(enabled)
  {
    if (enabled != this.isEnabled)
    {
      this.isEnabled = enabled;
      // $(this.clearNode).toggleClass('show', this.isEnabled && (this.isDirty || this.hasValue));
    }
  }

  setValue(imgurl)
  {
    // Set state
    this.isDirty = false;
    this.hasValue = true;
  }

  getValue(event)
  {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasValue)
    {
      // We have a set value
      event.detail.deferred.resolve(); // ADDME: this works as long as we're not allowing edits
    }
    else if (this.isDirty || this.canvasNode.dataset.forcedirty === '1')
    {
      // We have a drawing on canvas
      event.detail.deferred.resolve({ link: this.canvasNode.toDataURL(), filename: this.node.dataset.whFormName + ".png" });
    }
    else
    {
      // The field is empty
      event.detail.deferred.resolve(null);
    }
  }
}
