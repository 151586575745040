/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
button.splide__arrow.splide__arrow--prev {
  left: 0;
}

button.splide__arrow.splide__arrow--next {
  right: 0;
}

.splide__pagination__page.is-active {
  background-color: var(--ww-primary-color) !important;
}

.w-logoslider__scroller {
  width: calc(100% - 90px);
  margin: 0 auto;
}

.w-logoslider {
  --ww-widget-ctas-image-aspect-ratio: 100%;
  margin: 0 auto;
}

.w-logoslider__wrapper {
  gap: 35px;
}
.w-logoslider__imagelinkcontainer {
  max-width: 100% !important;
  margin-bottom: 0 !important;
  height: 100%;
}
.w-logoslider__imagelink {
  display: grid;
  align-content: center;
  height: 220px;
  position: relative;
  aspect-ratio: 1/1;
  width: 100%;
}
.w-logoslider__image {
  max-height: 100%;
  left: 0;
  top: 0;
  max-width: 100%;
}
.w-logoslider__imagelink {
  overflow: hidden;
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
@media (hover: hover) {
  .w-logoslider__imagelink:hover__image {
    transform: scale(1.1);
  }
}
.w-logoslider__image {
  transition: transform 0.3s;
}