.w-expandabletabs--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.w-expandabletabs--heading {
  margin: 0 0 0.3em;
}
.w-expandabletabs__item {
  width: calc(50% - 10px);
  margin-bottom: 0.5em;
}
@media screen and (max-width: 767px) {
  .w-expandabletabs__item {
    width: 100%;
  }
}
.w-expandabletabs__item__heading {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.w-expandabletabs__item__heading a {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.w-expandabletabs__item__heading .fa {
  margin-top: 0.25em;
}
.w-expandabletabs__item__content {
  display: none;
  padding: 15px;
  overflow: hidden;
}