/* global $ */
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import './customsubjects.scss';
import * as util from '../../js/utilities';

const numSubjectsRatingForm = 4;

export class CustomSubjectsDefineField {
  // constructor(node) // node = <div class="wh-form__fieldgroup wh-form__fieldgroup--custom customsubjectsdefine">
  constructor(node) {
    this.$node = $(node);

    this.$rowsContainer = this.$node.find('.customsubjectsdefine__rows');
    node.addEventListener("wh:form-getvalue", event => this._getValue(event));

    this.$node.find('.customsubjectsdefine__add').click(evt => {
      evt.preventDefault();
      this._addRow();
    });

    if (!whintegration.config.obj.editmode && whintegration.config.obj.formtype === 'rating') {
      for (let i = 0; i < numSubjectsRatingForm; i++) {
        this._addRow({ title: 'Selecteer een competentie', canremove: false, });
      }
    } else {
      for (const subject of this.$rowsContainer.data('subjects')) {
        this._addRow(subject);
      }
    }

    this._refreshDataIndices();
  }

  getSubjects() {
    let subjects = [];

    this.$node.find('.customsubjectsdefine__row').each(function() {
      subjects.push({
        title: $(this).find('.js-custom-subject-input').val(),
        isowner: true,
      });
    });

    return subjects;
  }

  _refreshDataIndices() {
    this._$getItems().each((idx, item) => {
      item.dataset.idx = idx;
      item.querySelector('.customsubjectsdefine__rowdelete').dataset.idx = idx;
    });
  }

  _$getItems() {
    return this.$rowsContainer.find('.customsubjectsdefine__row');
  }

  _addRow(subject) {
    util.devLog(subject);
    const $row = $(`<div class="customsubjectsdefine__row"></div>`);

    let $input;
    if (this.$rowsContainer.data('isdropdown')) {
      $input = $(`<select class="wh-form__pulldown wh-form__pulldown--primary js-custom-subject-input">
                    <option value="Selecteer een competentie" selected>Selecteer een competentie</option>
                  </select>`);

      for (const dropDownItem of whintegration.config.obj.ratingdropdownitems) {
        $input.append($('<option>', { value: dropDownItem, text: dropDownItem }));
      }

      if(subject != undefined) {
        $input.val(subject.title);
      }
    } else {
      $input = $(`<input type="text"
                         class="customsubjectsdefine__rowinput js-custom-subject-input"
                         value="${subject ? subject.title : ''}"
                         data-disabled="${subject ? 'true' : ''}"
                         placeholder="${whintegration.config.obj.formtype === 'rating' ? 'Vul hier een competentie in' : 'Vul hier je eigen onderwerp in'}"
                         />`);
    }

    if (subject) {
      if (!subject.canremove || !subject.isowner) {
        $row.addClass('customsubjectsdefine__row--cannotremove');
      }

      if (subject && !subject.isowner) {
        $row.attr('disabled', 'disabled');
      }

      //FIXME: [if not enabled]data-disabled="true"[/if] (if isdropdown, :disabled="!subject.isowner" [if not enabled]data-disabled="true"[/if])
      // $input.get(0).dataset.disabled = true;
    }

    $row.append($input);

    $row.append(`
      <a class="customsubjectsdefine__rowdelete js-subject-delete"
         v-if="subject.isowner && subject.canremove"
         href="#"
         @click.stop.prevent="removeCustomSubject(index)">
        <i class="fas fa-minus-circle"></i>
      </a>`);

    $row.find('.js-subject-delete').click(evt => {
      evt.preventDefault();
      this._deleteRow($row);
    });

    this.$rowsContainer.append($row);

    if (subject)
      $row.find('input').attr('disabled', 'disabled');
    else
      $row.find('input').focus();

    this._refreshDataIndices();
    dompack.dispatchCustomEvent(this.$rowsContainer.get(0), "gesprekkenapp:subjects-changed", { bubbles: true, cancelable: false, detail: { component: this } });
  }

  _deleteRow($row) {
    const idx = $row.get(0).dataset.idx;
    $row.remove();
    this._refreshDataIndices();
    dompack.dispatchCustomEvent(this.$rowsContainer.get(0), "gesprekkenapp:subject-removed", { bubbles: true, cancelable: false, detail: { idx: idx } });
  }

  // setValue(imgurl) {
  //   // Set state
  //   // this.isDirty = false;
  //   // this.hasValue = true;
  // }

  _getValue(evt) {
    dompack.stop(evt);

    dompack.dispatchCustomEvent(this.$rowsContainer.get(0), "gesprekkenapp:subjects-changed", { bubbles: true, cancelable: false, detail: { component: this } });

    evt.detail.deferred.resolve(this.getSubjects());
  }
}

export class CustomSubjectsInputsField {
  // constructor(node) // node = <div class="wh-form__fieldgroup--custom customsubjectsinputs">
  constructor(node) {
    this.$itemsContainer = $(node).find('.customsubjectsinputs__items');

    node.addEventListener("wh:form-getvalue", event => this._getValue(event));

    this.$defineSubjectsField = $(`.customsubjectsdefine__rows[data-guid="${this.$itemsContainer.data('definefield')}"]`);

    const subjects = this.$itemsContainer.data('subjects');
    if (!subjects) {
      this.$itemsContainer.addClass('nosubjects');
      return;
    }

    if(whintegration.config.obj.formtype !== 'development') {
      for (const subject of subjects) {
        this._addRow(subject);
      }
    }


    this.$defineSubjectsField.get(0).addEventListener("gesprekkenapp:subjects-changed", evt => {
      const subjects = evt.detail.component.getSubjects();
      const numSubjects = subjects.length;
      const $subjectInputs = this._$getSubjectItems();
      const numSubjectsInput = $subjectInputs.length;

      // if more subjects defined than inputs, add them
      if (numSubjects > numSubjectsInput) {
        for (let i = numSubjectsInput; i < numSubjects; i++) {

          if(whintegration.config.obj.formtype === 'development') {
            let base = ((i + 1) * 4) - 4;
            let devSubjects = this.$itemsContainer.data('subjects');
            this._addRow({ title: subjects[i].title, subjectInputs: devSubjects, base: base});
          } else {
            this._addRow({ title: subjects[i].title});
          }
        }
      }

      // update all titles
      subjects.forEach((subject, index) => {
        if(whintegration.config.obj.formtype === 'development') {
          const $itemContainer = $(this._$getSubjectItems()[index]);
          $itemContainer.find('.customsubjectsinputs__item__heading--head').text(subject.title);
        } else {
          const $itemContainer = $(this._$getSubjectItems()[index]);
          const defaultText = whintegration.config.obj.formtype === 'rating' ? 'Selecteer een competentie' : 'Vul een onderwerp in';
          $itemContainer.find('.wh-form__label').text(subject.title ?? defaultText);
        }
      });
    });

    this.$defineSubjectsField.get(0).addEventListener("gesprekkenapp:subject-removed", evt => {
      this._$getSubjectItems().eq(evt.detail.idx).remove();
    });
  }

  _$getSubjectItems() {
    if(whintegration.config.obj.formtype === 'development') {
      return this.$itemsContainer.find('.customsubjectsinputs__item--container');
    } else {
      return this.$itemsContainer.find('.customsubjectsinputs__item');
    }
  }

  _getSubjects() {
    let subjects = [];

    this._$getSubjectItems().each(function() {
      if(whintegration.config.obj.formtype === 'development') {
        let developmentSubjectItems = $(this).find('.customsubjectsinputs__item');
        let counter = 1;

        developmentSubjectItems.each(function(){
          subjects.push({
            title: $(this).find('.wh-form__label').text(),
            value: $(this).find('.wh-form__textarea').val(),
            groupclasses: (counter === 1 || counter === 2) ? ["hidefrompdf"] : [],
            rating: $(this).find('.customsubjectsdefine__rating').val(),
          });
          if(counter === 4)
            counter = 1;

          counter++;
        });
      } else {
        subjects.push({
          title: $(this).find('.wh-form__label').text(),
          value: $(this).find('.wh-form__textarea').val(),
          rating: $(this).find('.customsubjectsdefine__rating').val(),
          groupclasses: []
        });
      }
    });

    return subjects;
  }

  _addRow(subject) {
    const $item = $(`<div class="customsubjectsinputs__item wh-form__fieldgroup--textarea">`);

    const $textArea = $(`<textarea class="wh-form__textarea""></textarea>`);

    const isDisabled = this.$itemsContainer.data('disabled') === true;
    if (isDisabled) {
      $textArea.attr('readonly', 'readonly');
    }

    // FIXME: if not enabled $textArea.addClass('customsubjectsinputs__row--disabled')
    // FIXME: if not enabled $textArea.attr('disabled', 'true')

    if (subject) {
      $textArea.val(subject.value);
    }

    if (whintegration.config.obj.formtype === 'rating') {
      $item.append(`<label class="wh-form__label ${isDisabled ? 'customsubjectsinputs__row--disabled' : ''}"></label>`);

      $item.append(`<select class="wh-form__pulldown wh-form__pulldown--secondary customsubjectsdefine__rating [if not enabled]customsubjectsinputs__row--disabled[/if]"
                                 :disabled="!subject.isowner"
                                 [if not enabled]disabled="true"[/if]
                                 >
        <option value="" selected>Beoordeling</option>
        <option value="Onvoldoende">Onvoldoende</option>
        <option value="Matig">Matig</option>
        <option value="Voldoende">Voldoende</option>
        <option value="Goed">Goed</option>
      </select>`);

      $item.find('select').val(subject.rating);

      const $fieldLine = $(`<div class="wh-form__fields"><div class="wh-form__fieldline">`);
      $fieldLine.append($textArea);
      $item.append($fieldLine);
    } else if (whintegration.config.obj.formtype === 'development') {
      const idx = this._$getSubjectItems().length + 1;
      let $itemContainer = $(`<div class="customsubjectsinputs__item--container">`);

      let fields = ["Voorbereiding medewerker", "Voorbereiding leidinggevende", "Inhoud gesprek", "Te nemen acties"];

      for (let i = 0; i < fields.length; i++) {
        let $textArea = $(`<textarea class="wh-form__textarea""></textarea>`);

        if(i < 2) {
          $textArea.attr("placeholder", "De voorbereiding wordt niet meegenomen in het eindverslag.");
        }

        let $itemField = $(`<div class="customsubjectsinputs__item wh-form__fieldgroup--textarea">`);
        const isDisabled = this.$itemsContainer.data('disabled') === true;
        if (isDisabled) {
          $textArea.attr('readonly', 'readonly');
        }
        $itemField.append(`<label class="wh-form__label [if not enabled]customsubjectsinputs__row--disabled[/if]"
             data-bs-target="#c-custom-subject-value-${idx}-${(i + 1)}"
             data-bs-toggle="collapse">${fields[i]}</label>`);

        if(subject.subjectInputs.length > 0) {
          if(whintegration.config.obj.editmode) {
            let base = (subject.base) + i;
            console.log(`Base inside addrow: ${base}`);
            $itemField.val(subject.subjectInputs[base].title);
            $textArea.val(subject.subjectInputs[base].value);
          }else{
            $itemField.val(subject.subjectInputs[i].title);
            $textArea.val(subject.subjectInputs[i].value);
          }
        }

        $itemField.append($textArea);
        $itemContainer.append($itemField);
      }
      $itemContainer.prepend(`<div class="wh-form__fields customsubjectsinputs__item__heading"><div class="wh-form__fieldline"><div class="wh-form__richtext"><h3 class="heading3 customsubjectsinputs__item__heading--head">]${subject.title}</h3></div></div></div>`);

      this.$itemsContainer.append($itemContainer);

    } else {
      const idx = this._$getSubjectItems().length + 1;

      $item.append(`<label class="wh-form__label dropdown [if not enabled]customsubjectsinputs__row--disabled[/if]"
             data-bs-target="#c-custom-subject-value-${idx}"
             data-bs-toggle="collapse">${subject.title}</label>`);

      const $textAreaCollapser = $(`<div class="collapser collapse" id="c-custom-subject-value-${idx}">`);
      $textAreaCollapser.append($textArea);
      $item.append($textAreaCollapser);
    }

    if (whintegration.config.obj.formtype !== 'development') {
      this.$itemsContainer.append($item);
    }
  }

  _getValue(evt) {
    dompack.stop(evt);
    event.detail.deferred.resolve(this._getSubjects());
  }
}
