.wh-requireconsent__overlay:not([hidden]) + .w-thirdpartyframe__iframe {
  min-height: 200px;
  opacity: 0;
}

main > .widget--thirdpartyframe {
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
  width: 100%;
}
main > .widget--thirdpartyframe.full-width {
  max-width: unset;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
main > .widget--thirdpartyframe.full-width > div {
  max-width: var(--ww-grid-width);
  margin: 0 auto;
}