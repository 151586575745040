.w-verticaltabs {
  max-width: 100%;
}
.w-verticaltabs .wrapper {
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.w-verticaltabs__title {
  color: #000;
  text-align: center;
  margin: 0 0 50px;
}
.w-verticaltabs .swiper-container {
  overflow: visible;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  width: 100%;
}
.w-verticaltabs .swiper-slide {
  padding-left: 290px;
  box-sizing: border-box;
}
.w-verticaltabs__slide {
  background: #fff;
  padding: 30px;
}
.w-verticaltabs__buttons {
  display: flex;
  justify-content: flex-end;
}
.w-verticaltabs__button {
  display: inline-block;
  margin-left: 20px;
}
.w-verticaltabs__container {
  margin: 0 auto;
  max-width: 1260px;
  position: relative;
}
@media (max-width: 1260px) {
  .w-verticaltabs__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.w-verticaltabs__bullets {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 275px;
  display: flex;
  flex-direction: column;
}
.w-verticaltabs__bullet {
  display: inline-block;
  width: 100%;
  background: #fff;
  line-height: 100%;
  color: #000;
  padding: 15px;
  margin: 0;
  transition: border 0.3s;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
  transition: transform 0.25s, background 0.3s;
}
.w-verticaltabs__bullet:last-child {
  border-bottom: 0;
}
html.non-touch .w-verticaltabs__bullet:not(.selected):hover {
  transform: scale(1.03);
}
.w-verticaltabs .contentmodal {
  display: none;
  background-color: #fff;
  position: relative;
}
.w-verticaltabs .contentmodal.is-open {
  display: block;
}
@media screen and (min-width: 769px) {
  html.non-touch .w-verticaltabs__bullet.selected {
    background: #333;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .w-verticaltabs .swiper-wrapper,
  .w-verticaltabs .swiper-slide {
    display: none;
  }
  .w-verticaltabs__bullets {
    width: 100%;
    position: static;
  }
  .w-verticaltabs__carousel {
    padding: 0;
  }
  .w-verticaltabs__buttons {
    flex-direction: column;
  }
  .w-verticaltabs__button {
    margin: 0 0 10px;
  }
  .w-verticaltabs__dialog {
    padding: 10px 0;
  }
}

.w-verticaltabs .swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}