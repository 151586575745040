/* global $ */

import * as dompack from 'dompack';
import EventServerConnection from '@mod-system/js/net/eventserver';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-myforms')) {
    return;
  }

  let eventsConnection = new EventServerConnection({ url: "/wh_events/", log: dompack.debugflags.eventserver });
  eventsConnection.on('data', (event) => onBroadcastData(event));
  eventsConnection.setGroups(["update-pdf-state"]);
  eventsConnection.start();

  // don't know whether we need this
  // $('.js-formrow').click(function(evt) {
  //   if ($(this).hasClass('p-myforms__formrow--loading')) {
  //     evt.preventDefault();
  //     alert('nog bezig');
  //   }
  // });
});

function onBroadcastData(event) {
  if (event.msgs && event.msgs.length > 0 && event.msgs[0].gid === 'update-pdf-state') {
    let messageData = JSON.parse(event.msgs[0].msg);
    const $row = $(`.js-formrow[data-id=${messageData.id}]`);
    if ($row.length > 0) {
      $row.attr('href', messageData.link).removeClass('p-myforms__formrow--loading').attr('target', '_blank');
    }
  }
}
