/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* Widget: CTAs */
  --ww-widget-ctas-image-aspect-ratio: 56.25%; /* percentage: (H / B) * 100% (standaard is 9:16) */
  --ww-widget-ctas-image-max-width: 100%;
  --ww-widget-ctas-image-border-radius: 10px;
  --ww-widget-ctas-image-overlay: linear-gradient(
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 100%
  ); /* kan ook: rgba(0, 0, 0, 0.5) */
  --ww-widget-ctas-image-overlay-opacity-hover: 0.5;
  --ww-widget-ctas-image-transform-hover: scale(1.1);
  --ww-widget-ctas-grid-gap: 2rem;
  --ww-widget-ctas-col-box-shadow: inherit;
  --ww-widget-ctas-text-padding: 1rem;
  --ww-widget-ctas-text-background-color: #f2f2f5;
  --ww-widget-ctas-text-background-color-hover: #d5d5df;
  --ww-widget-ctas-text-color: #fff;
  --ww-widget-ctas-hasbgimage-padding-y: 3rem;
  --hover-image-scale: 1.1;
  --ww-widget-ctas-hover-box-size: 300px;
}

.widget--ctas.widget--hasbgimage {
  padding-top: var(--ww-widget-ctas-hasbgimage-padding-y);
  padding-bottom: var(--ww-widget-ctas-hasbgimage-padding-y);
}

.widget--ctas .w-ctas {
  margin: 0 auto;
}
.widget--ctas .w-ctas__container {
  display: grid;
  grid-gap: var(--ww-widget-ctas-grid-gap);
}
@media (min-width: 576px) {
  .widget--ctas .w-ctas__container--6, .widget--ctas .w-ctas__container--5, .widget--ctas .w-ctas__container--4, .widget--ctas .w-ctas__container--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .widget--ctas .w-ctas__container--6 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .widget--ctas .w-ctas__container--6 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .widget--ctas .w-ctas__container--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 768px) {
  .widget--ctas .w-ctas__container--5 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .widget--ctas .w-ctas__container--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 768px) {
  .widget--ctas .w-ctas__container--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .widget--ctas .w-ctas__container--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .widget--ctas .w-ctas__container--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.widget--ctas .w-ctas__imagelinkcontainer {
  max-width: var(--ww-widget-ctas-image-max-width);
}
.widget--ctas .w-ctas__imagelink {
  display: block;
  height: 0;
  position: relative;
  padding-bottom: var(--ww-widget-ctas-image-aspect-ratio);
}
.widget--ctas .w-ctas__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.widget--ctas .w-ctas__text {
  padding: var(--ww-widget-ctas-text-padding);
}
.widget--ctas .w-ctas__text > * {
  margin-top: 0;
  margin-bottom: 1em;
}
.widget--ctas .w-ctas__text h3 {
  font-size: 1.5rem;
}
.widget--ctas .w-ctas__text h4 {
  font-size: 1.125rem;
}
.widget--ctas .w-ctas__text .btn {
  margin-top: 1em;
}
.widget--ctas .w-ctas__cta-readmore {
  min-width: var(--ww-button-min-width);
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__text, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text {
  background-color: var(--ww-widget-ctas-text-background-color);
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  max-width: 100% !important;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__col-inner, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__col-inner {
  position: relative;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  margin-bottom: 0 !important;
  height: 100%;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text > *:first-child, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text > *:first-child {
  margin-top: 0;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text > *:last-child, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text > *:last-child {
  margin-bottom: 0;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text .btn, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text .btn {
  display: none;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text .widget--button .btn, .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text .widget--button .btn {
  display: unset;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__imagelink[href], .widget--ctas .w-ctas--blockstype-bgimage .w-ctas__imagelink[href] {
  overflow: hidden;
}
@media (hover: hover) {
  .widget--ctas .w-ctas--blockstype-textbelow .w-ctas__imagelink[href]:hover .w-ctas__image, .widget--ctas .w-ctas--blockstype-bgimage .w-ctas__imagelink[href]:hover .w-ctas__image {
    transform: var(--ww-widget-ctas-image-transform-hover);
  }
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__image, .widget--ctas .w-ctas--blockstype-bgimage .w-ctas__image {
  transition: transform 0.3s;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__imagelinkcontainer {
  overflow: hidden;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__col {
  border-radius: var(--ww-widget-ctas-image-border-radius);
  box-shadow: var(--ww-widget-ctas-col-box-shadow);
  overflow: hidden;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__col-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.widget--ctas .w-ctas--blockstype-textbelow .w-ctas__cta-readmorecontainer {
  margin-top: auto;
}
.widget--ctas .w-ctas--blockstype-bgimage .w-ctas__text > * {
  color: var(--ww-widget-ctas-text-color);
}
.widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text {
  justify-content: flex-start;
  text-decoration: none;
  position: static;
  height: auto;
  min-height: 100%;
  color: inherit;
  pointer-events: auto;
  border-radius: var(--ww-widget-ctas-image-border-radius);
  transition: background 0.3s;
}
@media (hover: hover) {
  .widget--ctas .w-ctas--blockstype-bgcolor .w-ctas__text:hover {
    background: var(--ww-widget-ctas-text-background-color-hover);
  }
}

.widget--ctas .w-ctas--overwrite-aspectratio .w-ctas__imagelink {
  height: auto;
  padding-bottom: 0;
}

.widget--ctas__padding > * {
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
}

.widget--ctas__titlecontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.widget--ctas__titlecontainer > h3 {
  margin-bottom: 0;
}

.widget--ctas__icon {
  max-width: 34px;
}

.w-ctas__imagelinkoverlay:hover ~ .w-ctas__image {
  transform: scale(1.1);
}

.w-ctas__popup {
  min-height: 20%;
  max-height: 80%;
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  overflow: hidden;
}
.w-ctas__popup--listener {
  cursor: pointer;
}
.w-ctas__popup--backdrop {
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.w-ctas__popup--content {
  width: 100%;
  height: 100%;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
  object-fit: cover;
  padding: 1.5rem;
  overflow-y: auto;
}
.w-ctas__popup--btncontainer {
  box-shadow: rgba(0, 0, 0, 0.07) 5px 5px 35px 0px;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.w-ctas__popup--listener:hover ~ .w-ctas__col-inner #w-ctasimg {
  transform: scale(var(--hover-image-scale));
}

#popup-backbutton {
  width: 24px !important;
  height: 24px !important;
  fill: var(--ww-primary-color);
}

.w-ctas__hover--container {
  display: inline-block;
  text-align: left;
  margin: 1%;
}
@media (hover: hover) {
  .w-ctas__hover--container:hover .w-ctas__hover--content {
    max-height: var(--ww-widget-ctas-hover-box-size);
  }
}

.w-ctas__hover--content {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  max-height: 0;
  border-radius: 8px;
}
.w-ctas__hover--inner {
  padding: 10px 15px 10px 15px;
}
.w-ctas__hover--toggle {
  border-radius: 3px;
  display: block;
}
.w-ctas__hover--min-content .w-ctas__col-inner {
  height: min-content !important;
}

.w-ctas__col-inner {
  border-radius: var(--ww-widget-ctas-image-border-radius);
  box-shadow: var(--ww-widget-ctas-col-box-shadow);
  overflow: hidden;
}

.w-ctas__imagelinkoverlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ww-widget-ctas-image-overlay);
  transition: opacity 0.3s;
}
@media (hover: hover) {
  .w-ctas__imagelinkoverlay:hover {
    opacity: var(--ww-widget-ctas-image-overlay-opacity-hover);
  }
}

.w-ctas__cta-description {
  word-break: break-word;
}