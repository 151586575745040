import * as dompack from 'dompack';
import * as WRDAuth from '@mod-wrd/js/auth';
import * as whintegration from '@mod-system/js/wh/integration';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-login'))
    return;

  console.info('page: login');
});

const wrdauth = WRDAuth.getDefaultAuth();

dompack.register(".samllogin__button", (elt) =>
{
  elt.addEventListener("click", async evt =>
  {
    let result = await wrdauth.startSAMLLogin("carmel", { action: 'redirect', allowlogout: false });
    whintegration.executeSubmitInstruction(result);
  });
});
