/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  --primary-color: #1b95ac;
  --secondary-color: #fdb304;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

.w-banner {
  margin-bottom: 20px;
  height: 560px;
  max-height: 40vh;
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 4vw));
}
@media (max-width: 1440px) {
  .w-banner {
    height: 400px;
  }
}
@media (max-width: 1024px) {
  .w-banner {
    height: 390px;
  }
}
@media (max-width: 812px) {
  .w-banner {
    height: 215px;
  }
}

.footer {
  background: #1A95AD;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #fff;
  margin-top: 4rem;
  text-align: center;
}
.footer a {
  color: #fff;
}
.footer .devider {
  padding: 0 15px;
}

.header {
  background-color: #1A95AD;
}
.header .navbar {
  background-color: #1A95AD;
  z-index: 1;
  padding: 8px 0;
}
.header .navbar-toggler {
  color: rgb(255, 255, 255) !important;
  border-color: none !important;
  font-size: 25px;
}
.header .navbar-brand img {
  display: none;
  height: 60px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}
@media (min-width: 992px) {
  .header .navbar-brand img {
    display: block;
  }
}
.header .navbar-brand-mobile {
  display: block;
}
.header .navbar-brand-mobile img {
  display: block;
  height: 40px;
  padding: 12px;
}
@media (min-width: 992px) {
  .header .navbar-brand-mobile {
    display: none;
    padding: 0;
    margin: 0;
  }
}
.header .navbar .user-menu {
  color: #fff;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  align-items: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}
@media (hover: hover) {
  .header .navbar .user-menu:hover {
    color: #FDB304;
  }
}
.header .navbar .user-menu__name {
  max-width: calc(100vw - 110px);
}
.header .navbar .user-menu span {
  font-size: 18px;
}
.header .navbar .user-menu .fa-user-circle {
  line-height: 2px;
  margin-right: 10px;
  font-size: 30px;
  color: #FDB304;
}
.header .navbar .user-menu::after {
  margin-left: 8px;
}
@media (min-width: 768px) {
  .header .navbar .user-menu {
    margin-left: 0;
  }
  .header .navbar .user-menu .dropdown-item {
    left: -60px;
  }
}
@media (min-width: 768px) {
  .header .navbar .dropdown-menu {
    left: -20px;
  }
}

.vcenter {
  display: flex;
  align-items: center;
}

html:not(.wh-wrdauth--isloggedin) .user-menu {
  display: none !important;
}

@media (min-width: 768px) {
  html.page-conversationform .wh-form__page .wh-form__carmelgrouptabscontainer {
    grid-column: span 2;
  }
}
html.page-conversationform .wh-form h1, html.page-conversationform .wh-form h2, html.page-conversationform .wh-form h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}
html.page-conversationform .wh-form__fullwidth {
  grid-column: span 2;
}
html.page-conversationform .wh-form--uploadgroup {
  align-items: center;
  flex: 0;
  grid-column: span 2;
  gap: 1em;
}
@media (max-width: 991.98px) {
  html.page-conversationform .wh-form--uploadgroup {
    flex-direction: column;
    align-items: flex-start;
  }
}
html.page-conversationform .wh-form__conversationdate {
  width: 300px;
}
html.page-conversationform .wh-form__error {
  color: red;
}
html.page-conversationform .wh-form__fieldgroup--checkbox {
  background-color: #1A95AD;
  width: 100%;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 8px;
  color: #fff;
  text-align: left;
  display: inline-flex;
}
html.page-conversationform .wh-form__fieldgroup--group {
  grid-column: span 2;
}
html.page-conversationform .wh-form__fieldgroup--date .wh-form__fields {
  flex-direction: column;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__fields {
  display: flex !important;
  position: relative;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__fieldline {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__fieldline > label {
  margin: 0;
  margin-left: 20px;
  line-height: 0;
  display: inline-block;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__fieldline--enabled-by {
  font-size: 0.8rem;
  line-height: 13px;
  display: flex;
  align-items: center;
  min-width: 130px;
  margin-right: 20px;
}
html.page-conversationform .wh-form__fieldgroup--upload {
  align-items: stretch;
}
html.page-conversationform .wh-form__fieldgroup--upload .filename {
  padding: 0.6rem 1rem;
  background: #dedede;
  border-radius: 10px;
}
html.page-conversationform .wh-form__fieldgroup--upload .filename--text {
  overflow: hidden;
  text-overflow: ellipsis;
}
html.page-conversationform .wh-form__fieldgroup--upload .wh-form__label {
  background-color: #1A95AD;
  color: #fff;
  padding: 0.6rem 1rem;
  min-width: unset;
  border-radius: 10px;
}
html.page-conversationform .wh-form__fieldgroup--textarea .wh-form__label {
  background-color: #1A95AD;
  border-color: #1A95AD;
  color: #fff;
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
html.page-conversationform .wh-form__fieldgroup--textarea .wh-form__label.dropdown:after {
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
}
html.page-conversationform .wh-form__fieldgroup--textarea .wh-form__label.dropdown:not([aria-expanded]):after, html.page-conversationform .wh-form__fieldgroup--textarea .wh-form__label.dropdown.collapsed:after {
  content: "\f0d7";
}
html.page-conversationform .wh-form__fieldgroup--textarea .wh-form__label--secondary {
  background-color: #FDB304;
  border-color: #FDB304;
}
html.page-conversationform .wh-form__fieldgroup--textarea .collapser {
  width: 100%;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__my-tooltipcontainer {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  width: 40px;
  text-decoration: none;
  position: absolute;
  right: 0;
}
html.page-conversationform .wh-form__fieldgroup.showasdisabled {
  pointer-events: none;
}
html.page-conversationform .wh-form__fieldgroup.showasdisabled .wh-form__my-tooltipcontainer {
  pointer-events: auto;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__optiondata {
  height: 100%;
}
html.page-conversationform .wh-form__fieldgroup .wh-form__optionlabel {
  margin: 0;
  font-size: 20px;
  line-height: 100%;
  word-break: break-word;
  padding: 15px 15px 15px 10px;
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  html.page-conversationform .wh-form__fieldgroup .wh-form__optionlabel {
    padding: 20px 20px 20px 10px;
  }
}
html.page-conversationform .wh-form__fieldgroup.selected .wh-form__grouptitle {
  background: #FDB304;
}
html.page-conversationform .wh-form__fieldgroup.selected .wh-form__grouptitle::after {
  border-top-color: #FDB304;
}
html.page-conversationform .wh-form .wh-form__button.hidden {
  opacity: 0;
  pointer-events: none;
}
html.page-conversationform .wh-form .wh-form__pulldown {
  background-color: #1A95AD;
  color: #fff;
  cursor: pointer;
}
html.page-conversationform .wh-form .wh-form__pulldown--secondary {
  background-color: #FDB304;
}
html.page-conversationform .wh-form .wh-form__buttongroup {
  display: flex;
  justify-content: space-between;
}
html.page-conversationform .wh-form .wh-form__button--next {
  order: 2;
  margin-left: 0;
}
@media (min-width: 768px) {
  html.page-conversationform .wh-form .wh-form__button--next {
    margin-left: 32px;
  }
}
html.page-conversationform .wh-form .wh-form__button--save {
  display: inline-flex;
  margin: 0 0 10px auto;
  width: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
  html.page-conversationform .wh-form .wh-form__button--save {
    width: auto;
  }
}
html.page-conversationform .wh-form__myfieldgrouptab {
  display: none;
}
html.page-conversationform .wh-form__myfieldgrouptab.selected {
  display: block;
}
@media (min-width: 576px) {
  html.page-conversationform .wh-form__carmelgrouptabscontainer .wh-form__fieldgroup--group {
    width: 100%;
  }
}
@media (min-width: 768px) {
  html.page-conversationform .wh-form__myfieldgrouptab {
    display: block;
  }
  html.page-conversationform .wh-form__carmelgrouptabscontainer {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
  }
  html.page-conversationform .wh-form__carmelgrouptabscontainer .wh-form__fieldgroup--group {
    width: 32%;
  }
  html.page-conversationform .wh-form__carmelgrouptabscontainer .wh-form__carmeltabitem {
    order: 2;
  }
}

html.page-conversationform .fa-question-circle {
  font-size: 25px;
  line-height: 100%;
  margin-left: 20px;
}
@media (min-width: 768px) {
  html.page-conversationform .fa-question-circle {
    font-size: 30px;
    line-height: 100%;
  }
}
html.page-conversationform .tooltip {
  display: flex;
  justify-content: center;
  width: 100%;
}
html.page-conversationform .tooltip .arrow:before {
  border-top-color: #fff;
}
html.page-conversationform .tooltip .tooltip-inner {
  max-width: initial !important;
  background: #fff !important;
  color: #000 !important;
  width: 100%;
}
@media (min-width: 992px) {
  html.page-conversationform .tooltip .tooltip-inner {
    max-width: var(--breakpoint-xl) !important;
  }
}
html.page-conversationform .otherusers {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}

.page-login .crumbpath {
  display: none;
}

.p-login {
  text-align: center;
}

.p-myforms__container .col {
  background-clip: content-box;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .p-myforms__container .col {
    margin-bottom: 0;
  }
}
.p-myforms__title, .p-myforms__formrow {
  padding: 16px;
}
.p-myforms__title {
  background-color: #1A95AD;
  color: #fff;
}
.p-myforms__title--employee {
  background-color: #FDB304;
  border: 1px solid transparent;
  border-color: #FDB304;
}
.p-myforms__formrow {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #dedede;
  color: #000;
  border-left: 1px solid #929292;
  border-right: 1px solid #929292;
  border-top: 1px solid #929292;
}
@media (hover: hover) {
  .p-myforms__formrow[href]:hover {
    text-decoration: none;
    background: #dadada;
    color: #1A95AD;
  }
}
.p-myforms__formrow--loading .p-myforms__formrowicon--download {
  display: none !important;
}
.p-myforms__formrow--loading .p-myforms__formrowicon--loading {
  display: inline !important;
}
.p-myforms__formrow:last-of-type {
  border-bottom: 1px solid #929292;
}
.p-myforms__formrowicon {
  margin-left: 16px;
}
.p-myforms__formrowicon--loading {
  display: none !important;
}

h1, h2, h3, .navbar a, .user-menu {
  font-family: Avenir, sans-serif;
  font-weight: bold;
}

h1, h2, h3 {
  margin-bottom: 20px;
  color: #383838;
}

h1 {
  font-size: 45px;
}
@media (max-width: 812px) {
  h1 {
    font-size: 35px;
  }
}

h2 {
  font-size: 35px;
}
@media (max-width: 812px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 30px;
}
@media (max-width: 812px) {
  h3 {
    font-size: 25px;
  }
}

a {
  color: #1A95AD;
  transition: color 0.3s;
}
a:hover {
  color: #FDB304;
}

.wh-form__fieldgroup--signature {
  width: 100%;
  overflow: hidden;
}
.wh-form__fieldgroup--signature[data-disabled=true] {
  opacity: 0;
  height: 0;
}
.wh-form__fieldgroup--signature[data-isset=true] {
  opacity: 1;
  height: auto;
}
.wh-form__fieldgroup--signature[data-isset=true] .signature {
  display: flex;
  flex-direction: column;
}
.wh-form__fieldgroup--signature[data-isset=true] .signature__name {
  order: 2;
}
.wh-form__fieldgroup--signature[data-isset=true] .signature__img {
  order: 1;
}

.signature {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.signature__name {
  font-weight: bold;
}
.signature--disabled {
  border: 0;
  height: auto;
}
.signature__img {
  width: 70%;
}
.signature__input {
  cursor: pointer;
  height: 180px;
  width: 100%;
}
.signature__value {
  height: 100%;
  width: 100%;
}
.signature__icon {
  font-size: 28px;
  line-height: 100%;
  position: absolute;
  top: -30px;
  color: #000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
}
.signature__icon.show {
  display: flex;
}
.signature__undo {
  font-size: 24px;
  line-height: 100%;
  right: 40px;
}
.signature__clear {
  right: 0;
}

.wh-form__label {
  min-width: 240px;
}
.wh-form__richtext {
  width: 100%;
}
.wh-form__grouptitle {
  font-family: Avenir, sans-serif;
}
@media (min-width: 768px) {
  .wh-form__page .wh-form__fieldgroup.wh-form__fieldgroup--richtext,
  .wh-form__page .wh-form__fieldgroup.wh-form__fieldgroup--signature,
  .wh-form__page .wh-form__fieldgroup.wh-form__fieldgroup--upload {
    grid-column: span 2;
  }
  .wh-form__page--visible {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
  }
}
.wh-form__fieldgroup--required label::after {
  content: "*";
}
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline, .wh-form__fieldgroup--radiogroup .wh-form__fieldline, .wh-form__fieldgroup--checkbox .wh-form__fieldline {
  align-items: start;
  height: auto;
  min-height: 50px;
}
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline label, .wh-form__fieldgroup--radiogroup .wh-form__fieldline label, .wh-form__fieldgroup--checkbox .wh-form__fieldline label {
  margin: 5px 0;
}
.wh-form__fieldgroup--checkboxgroup .wh-form__optiondata, .wh-form__fieldgroup--radiogroup .wh-form__optiondata, .wh-form__fieldgroup--checkbox .wh-form__optiondata {
  margin-left: 10px;
  margin-right: 10px;
}
.wh-form__fieldgroup--textarea, .wh-form__fieldgroup--textedit, .wh-form__fieldgroup--pulldown, .wh-form__fieldgroup--checkboxgroup, .wh-form__fieldgroup--radiogroup, .wh-form__fieldgroup--signature, .wh-form__fieldgroup--checkbox, .wh-form__fieldgroup--date, .wh-form__fieldgroup--time {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.wh-form__fieldgroup--textarea .wh-form__fields, .wh-form__fieldgroup--textedit .wh-form__fields, .wh-form__fieldgroup--pulldown .wh-form__fields, .wh-form__fieldgroup--checkboxgroup .wh-form__fields, .wh-form__fieldgroup--radiogroup .wh-form__fields, .wh-form__fieldgroup--signature .wh-form__fields, .wh-form__fieldgroup--checkbox .wh-form__fields, .wh-form__fieldgroup--date .wh-form__fields, .wh-form__fieldgroup--time .wh-form__fields {
  flex: auto;
  width: 100%;
}
.wh-form__fieldgroup--hidden {
  display: none;
}
.wh-form__textinput, .wh-form__textarea,
.wh-form select {
  width: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  background: #dedede;
  border: none;
}
.wh-form .wh-rtd__toolbarstyle {
  padding: 0;
  width: auto;
  background: none;
  font-size: 14px;
}
.wh-form__fieldline input[type=number] {
  width: auto;
}
.wh-form__subfield {
  align-items: center;
}
.wh-form__textarea {
  height: 150px;
}
.wh-form .signature .fas {
  color: #1A95AD;
}
.wh-form .signature__input {
  border-radius: 10px;
  background: #dedede;
  border: none;
}
.wh-form__uploadfieldselect {
  padding: 10px 30px;
  margin-bottom: 0;
}
.wh-form__dateinputgroup, .wh-form__timeinputgroup {
  width: auto;
  display: block;
  padding: 0;
  border-radius: 10px;
  border: none;
  background: #dedede;
}
.wh-form__dateinputgroup input, .wh-form__timeinputgroup input {
  background: none;
  color: #000;
}
.wh-form__dateinputgroup input[type=number], .wh-form__timeinputgroup input[type=number] {
  width: 45px;
}
.wh-form__dateinputgroup__line.year input {
  width: 90px !important;
}
.wh-form__optiondata {
  align-items: center;
}
.wh-form__uploadfieldfilename {
  margin-right: 10px;
}
.wh-form__infotextwrapper {
  display: none;
}
.wh-form__buttongroup {
  display: inline-block;
}
@media (max-width: 767px) {
  .wh-form__buttongroup {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-direction: column;
  }
}
.wh-form .savebutton, .wh-form__buttongroup {
  margin-top: 15px;
}
.wh-form__button--submit {
  float: right;
}
.wh-form__button {
  background-color: #1A95AD;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 15px 30px;
  text-align: center;
  margin-bottom: 10px;
}
.wh-form__button--previous {
  margin-right: 0;
}
@media (min-width: 768px) {
  .wh-form__button--previous {
    margin-right: 10px;
  }
}
.wh-form__button:disabled, .wh-form__button[disabled], .wh-form__button[disabled=disabled] {
  opacity: 0.2 !important;
  pointer-events: none;
}
.wh-form__button--next {
  margin-left: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .wh-form__button--next {
    width: auto;
  }
}
.wh-form:not(.wh-form--allowprevious) .wh-form__navbuttons {
  justify-content: flex-end !important;
}
.wh-form input[type=radio] + label:before {
  border-radius: 100%;
}
.wh-form textarea:focus, .wh-form input:focus, .wh-form select:focus {
  outline: none;
}
.wh-form .wh-anchor {
  height: 80px;
  margin-top: -80px;
}
.wh-form input[type=checkbox],
.wh-form input[type=radio] {
  display: none;
}
.wh-form input[type=checkbox] + label,
.wh-form input[type=radio] + label {
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 7px;
  cursor: pointer;
}
.wh-form input[type=checkbox] + label:before,
.wh-form input[type=radio] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #1A95AD;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  text-transform: none !important;
  color: #fff;
  content: "\f00c";
  display: grid;
  place-items: center;
  height: 30px;
  width: 30px;
  padding: 5px;
}
.wh-form input[type=checkbox] + label:after,
.wh-form input[type=radio] + label:after {
  content: "";
  background: #1A95AD;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 300ms;
}
.wh-form input[type=checkbox]:checked + label:after,
.wh-form input[type=radio]:checked + label:after {
  transform: translateX(100%);
}

@media (max-width: 550px) {
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .wh-form__fields {
    width: 100%;
  }
}
body {
  font-size: 18px;
  line-height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: Avenir, sans-serif;
  line-height: 30px;
}
body > main {
  flex: 1;
}

:root {
  --ww-header-height: 2rem;
  --ww-widget-ctas-image-aspect-ratio: 100%;
  --ww-widget-ctas-text-padding: 2rem;
  --ww-footer-margin-top: 4rem;
  --ww-footer-padding-top: 2rem;
  --ww-footer-padding-bottom: 2rem;
  --ww-widget-ctas-image-overlay: linear-gradient(
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.btn-success {
  background-color: #198754 !important;
  border-color: #198754 !important;
}

.btn,
.w-expandabletabs__item__heading, .button,
.wh-form__button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  padding: 0.6rem 1rem;
}

.button,
.btn,
.w-expandabletabs__item__heading,
.wh-form__button {
  background-color: var(--secondary-color);
  color: #fff;
  border-color: var(--secondary-color);
  text-decoration: none;
  transition: background-color 300ms;
}
.button--previous,
.btn--previous,
.wh-form__button--previous {
  background-color: #929292;
}
.button:hover, .button:focus,
.btn:hover,
.w-expandabletabs__item__heading:hover,
.btn:focus,
.w-expandabletabs__item__heading:focus,
.wh-form__button:hover,
.wh-form__button:focus {
  background-color: #1A95AD;
  color: #fff;
}
.button--save,
.btn--save,
.wh-form__button--save {
  background-color: #1A95AD;
}
.button--save:hover, .button--save:focus,
.btn--save:hover,
.btn--save:focus,
.wh-form__button--save:hover,
.wh-form__button--save:focus {
  background-color: #FDB304;
}
.button.disabled,
.btn.disabled,
.disabled.w-expandabletabs__item__heading,
.wh-form__button.disabled {
  cursor: default;
  background-color: #ccc;
  outline: none;
}

.wh-form:not(.wh-form--allownext) .wh-form__button--save {
  background-color: #FDB304;
}
.wh-form:not(.wh-form--allownext) .wh-form__button--save:hover, .wh-form:not(.wh-form--allownext) .wh-form__button--save:focus {
  background-color: #1A95AD;
}

.w-overview__item {
  border-radius: 10px;
  height: 350px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.w-overview__item:hover {
  text-decoration: none;
}
.w-overview__item--overlaytitle {
  display: none;
}
.w-overview__item--title {
  font-family: Avenir, sans-serif;
  color: #fff;
  box-sizing: border-box;
  font-size: 25px;
  line-height: 100%;
  font-weight: bold;
  text-align: left;
  margin: 0 30px;
}

.crumbpath {
  display: flex;
  margin: 15px 0 15px;
  align-items: center;
  flex-flow: row wrap;
}
.crumbpath,
.crumbpath a {
  font-size: 17px;
  line-height: 100%;
  color: #1A95AD;
  font-weight: bold;
}
.crumbpath a.crumbpath__item {
  text-decoration: none;
  white-space: nowrap;
}
@media (hover: hover) {
  .crumbpath a.crumbpath__item:hover {
    text-decoration: underline;
  }
}
.crumbpath__items a, .crumbpath__items span {
  padding-left: 4px;
  padding-right: 4px;
}
.crumbpath__item--currentpage {
  color: #FDB304;
}
.crumbpath__separator {
  margin: 0 9px;
}
.crumbpath__separator .icon-pathseparator {
  stroke: #1A95AD;
}

html.wait-for-init body {
  opacity: 0;
  pointer-events: none;
}

.bootbox .modal-footer button {
  min-width: 100px;
}

.container-xl .ww-grid {
  padding-left: 0;
  padding-right: 0;
}

.ww-p-overview .c-filters .wh-form__fieldgroup--pulldown .wh-form__fieldline .wh-form__pulldown,
html.page-conversationform .wh-form .wh-form__pulldown {
  appearance: none;
  background-image: url(./web/img/caret-down-white.svg);
  background-repeat: no-repeat;
  background-position: 96%;
  background-size: 30px 30px;
}

.ww-p-overview {
  margin-top: 48px;
}
.ww-p-overview .c-filters {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .ww-p-overview .c-filters {
    max-width: calc(50% - 1rem);
  }
}
.ww-p-overview .c-filters .wh-form__fieldgroup {
  margin-left: 0;
}
.ww-p-overview .c-filters .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  position: relative;
}
.ww-p-overview .c-filters .wh-form__fieldgroup--pulldown .wh-form__fieldline:after {
  font-size: 40px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
  width: 58px;
  height: 100%;
  pointer-events: none;
}
.ww-p-overview .c-filters select {
  background-color: #1A95AD;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 15px 30px;
}

.w-expandabletabs__item__heading {
  background-color: #1A95AD;
  color: #fff;
  border-radius: 10px;
}
.w-expandabletabs__item__heading--expand {
  color: #fff;
  text-decoration: none;
}
.w-expandabletabs__item__content {
  background-color: #dedede;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.w-ctas--blockstype-bgimage .w-ctas__text h1,
.w-ctas--blockstype-bgimage .w-ctas__text h2,
.w-ctas--blockstype-bgimage .w-ctas__text h3,
.w-ctas--blockstype-bgimage .w-ctas__text p {
  color: #fff;
}