/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-history__milestone {
  margin: 20px 0;
}

.year {
  color: var(--mso-history-color);
  font-size: 80px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  word-break: break-word;
}

.w-history__milestone {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 767.98px) {
  .w-history__milestone {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.w-history__milestones-wrapper {
  position: relative;
}

.w-history__milestones-wrapper::before {
  content: "";
  height: 2px;
  width: 100%;
  background: var(--mso-history-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .w-history__milestones-wrapper::before {
    visibility: hidden;
  }
}

.w-history__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 80px;
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .w-history__wrapper {
    overflow-x: hidden;
  }
}

.w-history__dot {
  width: 30px;
  height: 30px;
  background: var(--mso-history-color);
  order: 2;
}
@media (max-width: 767.98px) {
  .w-history__dot {
    display: none;
  }
}

.w-history__wrapper::before {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  background: var(--mso-history-color);
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767.98px) {
  .w-history__wrapper::before {
    visibility: hidden;
  }
}

.w-history__text {
  max-width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 7px;
  order: 1;
  height: 100%;
  text-align: right;
}
.w-history__text--top {
  align-self: flex-end;
}
.w-history__text p.normal {
  font-size: 20px;
}
@media (min-width: 576px) {
  .w-history__text {
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .w-history__text {
    grid-template-rows: 1fr;
  }
}

.odd .w-history__text {
  text-align: left;
}

.w-history__imagecontainer {
  order: 3;
  background: #fff;
  z-index: 2;
}

@media (min-width: 576px) {
  .odd .w-history__text {
    order: 3;
  }
  .odd .w-history__imagecontainer {
    order: 1;
  }
}
.w-history__start,
.w-history__end {
  position: absolute;
  width: 50px;
  height: 50px;
  background: var(--mso-history-color);
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767.98px) {
  .w-history__start,
  .w-history__end {
    display: none;
  }
}

.w-history__start {
  top: -50px;
}

.w-history__end {
  bottom: -50px;
}

.w-history__imagecontainer {
  display: block;
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}

.w-history__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}