/* global bootbox */
import * as WRDAuth from '@mod-wrd/js/auth';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';

import './shared/css/functions.scss';
// import '@mod-ww/widgets/widgets';
import '@mod-mso/webdesigns/mso/widgets/widgets';

import './shared/forms/forms';
import './shared/rtd/rtd';

import { WWForms } from '@mod-ww/webdesigns/template/ww';

import './gesprekkenapp.scss';

// import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
// openLinksInNewWindow();

// components & widgets
import './footer/footer';
import './header/header';

// pages
import './pages/conversationform/conversationform';
import './pages/home/home';
import './pages/knowledgebase/knowledgebase';
import './pages/login/login';
import './pages/myforms/myforms';

import PageOverview from '@mod-ww/pages/overview/overview';

window.whintegration = whintegration;

const wrdauth = WRDAuth.getDefaultAuth();
dompack.register(".loginbutton", (elt) => {
  elt.addEventListener("click", async evt => {
    let result = await wrdauth.startSAMLLogin("test", { action: 'redirect', allowlogout: false });
    whintegration.executeSubmitInstruction(result);
  });
});

dompack.onDomReady(() => {
  bootbox.setDefaults({ locale: 'nl', swapButtonOrder: true, });

  new WWForms({ splitDateField: false });

  // set a timeout to make sure we show the page even with errors
  window.setTimeout(() => document.documentElement.classList.remove('wait-for-init'), 2000);
  dompack.register('.ww-p-overview', node => new PageOverview(node));
});
