/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.wh-form__fieldgroup--custom.customsubjectsdefine,
.wh-form__fieldgroup--custom.customsubjectsinputs,
.customsubjectsinputs__items > p {
  grid-column: span 2;
}

html.page-conversationform .wh-form__fieldgroup--custom.customsubjectsdefine .wh-form__fields,
html.page-conversationform .wh-form__fieldgroup--custom.customsubjectsinputs .wh-form__fields {
  display: block !important;
}

.wh-form__fields .customsubjectsdefine, .wh-form__fields .customsubjectsdefine__rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 768px) {
  .wh-form__fields .customsubjectsdefine, .wh-form__fields .customsubjectsdefine__rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.wh-form__fields .customsubjectsdefine__rows {
  margin-bottom: 1rem;
}
.wh-form__fields .customsubjectsdefine__rows[data-disabled=true] .wh-form__pulldown {
  background-image: none !important;
  pointer-events: none;
}
.wh-form__fields .customsubjectsdefine__error {
  grid-column: span 2;
}
.wh-form__fields .customsubjectsdefine__add {
  background-color: #FDB304;
  border-color: #FDB304;
  border-radius: 10px;
  color: #fff;
  width: max-content;
  grid-column: span 2;
  justify-self: flex-start;
}
.wh-form__fields .customsubjectsdefine__add .fas {
  margin-left: 0.5rem;
}
.wh-form__fields .customsubjectsdefine__row {
  background-color: #1A95AD;
  padding: 1.2rem 0 1.2rem 1.2rem;
  border-radius: 10px;
  transition: background-color 0.3s;
  display: flex;
}
.wh-form__fields .customsubjectsdefine__row[disabled=true] {
  opacity: 0.8;
  cursor: not-allowed;
}
.wh-form__fields .customsubjectsdefine__row[disabled=true] input {
  pointer-events: none;
}
.wh-form__fields .customsubjectsdefine__row--cannotremove {
  padding-right: 1.2rem !important;
}
.wh-form__fields .customsubjectsdefine__row--cannotremove .customsubjectsdefine__rowdelete {
  display: none;
}
.wh-form__fields .customsubjectsdefine__rowinput {
  background-color: #136E81;
  border: none;
  border-radius: 10px;
  color: #fff;
  flex: 1;
  padding: 0.5rem 1rem;
  text-overflow: ellipsis;
  max-width: 100%;
}
.wh-form__fields .customsubjectsdefine__rowinput::placeholder {
  color: #ccc;
}
.wh-form__fields .customsubjectsdefine__rowinput[disabled] {
  background: none;
  padding-left: 0;
}
.wh-form__fields .customsubjectsdefine__rowinput[disabled] + .customsubjectsdefine__rowdelete {
  visibility: hidden;
  pointer-events: none;
}
.wh-form__fields .customsubjectsdefine__rowdelete {
  color: #fff;
  width: 15%;
  display: grid;
  place-items: center;
  text-decoration: none;
}
.wh-form__fields .customsubjectsdefine__rowdelete:hover {
  color: #E6E6E6;
}

.customsubjectsinputs {
  width: 100%;
}
.customsubjectsinputs__nosubjects {
  display: none;
}
.customsubjectsinputs__nosubjects .customsubjectsinputs__items.nosubjects {
  display: block;
}
@media (min-width: 768px) {
  .customsubjectsinputs__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
.customsubjectsinputs__items[data-disabled=true] .wh-form__pulldown {
  background-image: none !important;
  pointer-events: none;
}
@media (min-width: 768px) {
  .customsubjectsinputs__item--container {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
.customsubjectsinputs__item__heading {
  grid-column: 1/-1;
}
.customsubjectsinputs__row--disabled {
  opacity: 0.8 !important;
}