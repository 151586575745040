/* global bootbox */
import * as dompack from 'dompack';

export function devLog(...args) {
  if (dompack.debugflags.debug) {
    console.log(...args);
  }
}

export function triggerErrorDialog(title, text, errorDetails) {
  if (errorDetails)
    console.error(errorDetails);

  alert(text, null, title);
}

export function triggerGeneralErrorDialog(err) {
  triggerErrorDialog('Foutmelding', 'Er ging iets mis bij het verwerken van de actie. Probeer het opnieuw of neem contact met ons op als het probleem zich blijft voordoen.', err);
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function alert(message, callback, title) {
  bootbox.alert({
    buttons: {
      ok: {
        className: 'btn-primary'
      },
    },
    title,
    message,
    callback,
    closeButton: false,
  });
}

export function confirm(message, callback, title) {
  bootbox.confirm({
    buttons: {
      confirm: {
        label: 'Ja',
        className: 'btn-success'
      },
      cancel: {
        label: 'Nee',
      }
    },
    title,
    message,
    callback,
    closeButton: false,
  });
}
