.w-slideshow, .w-videoslider {
  position: relative;
  height: var(--ww-widget-slideshow-height);
  max-height: var(--ww-widget-slideshow-max-height);
}
.w-slideshow__item, .w-videoslider__item {
  width: 100%;
  height: var(--ww-widget-slideshow-height);
  max-height: var(--ww-widget-slideshow-max-height);
}
.w-slideshow__bgoverlay, .w-videoslider__bgoverlay, .w-slideshow__bgimage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.w-slideshow__bgoverlay, .w-videoslider__bgoverlay {
  z-index: 5;
  background: var(--ww-widget-slideshow-bg-overlay);
  pointer-events: none;
}
.w-slideshow__textcontainer, .w-videoslider__textcontainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2000;
  margin: 0 auto;
  width: var(--ww-grid-width);
  max-width: calc(100vw - var(--ww-grid-margin-outer)) !important;
}
.w-slideshow__text, .w-videoslider__text {
  color: #fff;
  height: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.w-slideshow__text > h1, .w-videoslider__text > h1,
.w-slideshow__text > h2,
.w-videoslider__text > h2,
.w-slideshow__text > h3,
.w-videoslider__text > h3,
.w-slideshow__text > h4,
.w-videoslider__text > h4,
.w-slideshow__text > p,
.w-videoslider__text > p {
  text-shadow: var(--ww-widget-slideshow-text-shadow);
}
.w-slideshow__text > h2, .w-videoslider__text > h2 {
  margin-bottom: 0.7em;
}
.w-slideshow .swiper-button-next, .w-videoslider .swiper-button-next {
  right: 1rem;
}
.w-slideshow .swiper-button-prev, .w-videoslider .swiper-button-prev {
  left: 1rem;
}
.w-slideshow .swiper-pagination-bullets, .w-videoslider .swiper-pagination-bullets {
  bottom: 30px;
}
.w-slideshow .container, .w-videoslider .container {
  text-align: center;
  max-width: 60vw;
}
.w-slideshow .swiper-button-prev, .w-videoslider .swiper-button-prev,
.w-slideshow .swiper-button-next,
.w-videoslider .swiper-button-next,
.w-slideshow .swiper-pagination-bullets,
.w-videoslider .swiper-pagination-bullets {
  display: none;
}
@media screen and (min-width: 1024px) {
  .w-slideshow--has-slides .swiper-button-prev,
  .w-slideshow--has-slides .swiper-button-next {
    display: flex;
  }
}
.w-slideshow--has-slides .swiper-pagination-bullets {
  display: block;
}

.w-videoslider {
  overflow: hidden;
}
.w-videoslider__bgoverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.w-videoslider__bgvideo {
  aspect-ratio: 16/9;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.w-videoslider__iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.w-videoslider__text {
  max-width: 530px;
  align-items: flex-start;
  text-align: left;
}
.w-videoslider__text > h1,
.w-videoslider__text > h2,
.w-videoslider__text > h3,
.w-videoslider__text > h4,
.w-videoslider__text > p {
  text-shadow: var(--ww-widget-slideshow-text-shadow);
}
.w-videoslider__text > h2 {
  margin-bottom: 0.7em;
}