.wh-form__fieldgroup--fileupload {
  flex-direction: column;
  gap-y: 1em;
  grid-column: span 2;
  /* use a hand cursor intead of arrow for the action buttons */
  /* the text color of the drop label*/
  /* underline color for "Browse" button */
  /* the background color of the filepond drop area */
  /* the border radius of the drop area */
  /* the border radius of the file item */
  /* the background color of the file and file panel (used when dropping an image) */
  /* the background color of the drop circle */
  /* the background color of the black action buttons */
  /* the icon color of the black action buttons */
  /* the color of the focus ring */
  /* the text color of the file status and info labels */
  /* error state color */
  /* bordered drop area */
}
.wh-form__fieldgroup--fileupload .fileupload {
  width: 100%;
  max-width: 300px;
}
.wh-form__fieldgroup--fileupload .filepond--file-action-button {
  cursor: pointer;
}
.wh-form__fieldgroup--fileupload .filepond--drop-label {
  color: #fff;
}
.wh-form__fieldgroup--fileupload .filepond--label-action {
  text-decoration-color: #fff;
}
.wh-form__fieldgroup--fileupload .filepond--panel-root {
  background-color: #1A95AD !important;
}
.wh-form__fieldgroup--fileupload .filepond--panel-root {
  border-radius: 10px;
}
.wh-form__fieldgroup--fileupload .filepond--item-panel {
  border-radius: 10px;
}
.wh-form__fieldgroup--fileupload .filepond--item-panel {
  background-color: #fff;
}
.wh-form__fieldgroup--fileupload .filepond--drip-blob {
  background-color: rgba(255, 255, 255, 0.4);
}
.wh-form__fieldgroup--fileupload .filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}
.wh-form__fieldgroup--fileupload .filepond--file-action-button {
  color: white;
}
.wh-form__fieldgroup--fileupload .filepond--file-action-button:hover,
.wh-form__fieldgroup--fileupload .filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}
.wh-form__fieldgroup--fileupload .filepond--file {
  color: #555;
}
.wh-form__fieldgroup--fileupload [data-filepond-item-state*=error] .filepond--item-panel,
.wh-form__fieldgroup--fileupload [data-filepond-item-state*=invalid] .filepond--item-panel {
  background-color: red;
}
.wh-form__fieldgroup--fileupload [data-filepond-item-state=processing-complete] .filepond--item-panel {
  background-color: green;
}
.wh-form__fieldgroup--fileupload .filepond--panel-root {
  background-color: transparent;
  border: 1px solid #1A95AD;
}